const Replicated = (token, tokenParsed, sethId) => {
    // console.log('id');console.log(tokenParsed.sub);
    // On regarde si l'id est dans la db
    return new Promise((resolve, reject) => {
        fetch("/api/users/", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                console.log("res");
                console.log(res);
                if (!res.user) {
                    // Checks if the user has a deleted account
                    if (res.message == "Account deleted") {
                        resolve("deleted");
                        return;
                    }
                    // On demande la création du user
                    ask_create_user(token, tokenParsed);
                    checkInvitations(token);
                    resolve("created");
                    return;
                } else {
                    update_user(token, tokenParsed);
                    checkInvitations(token);
                    // If the user did'nt finish his inscription
                    if (res.user.uBirthDate == null || res.user.uJob == null) {
                        resolve("created");
                    } else {
                        resolve("updated");
                        sethId(res.user.uLastHotel);
                    }

                    return;
                }
            })
            .catch((err) => {
                console.log("err");
                console.log(err);
                reject(err);
            });
    });
};

const checkInvitations = (token) => {
    console.log("check invitations");
    fetch("/api/hotels/invitations/invitedUserId", {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
        .then((res) => {
            // console.log('res');console.log(res);
            return res.json();
        })
        .then((res) => {
            console.log("res");
            console.log(res);
        })
        .catch((err) => {
            console.log("err");
            console.log(err);
        });
};

const ask_create_user = (token, tokenParsed) => {
    // console.log('token');console.log(token);
    console.log("create user");
    fetch("/api/users/", {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
            uId: tokenParsed.sub,
            name: tokenParsed.given_name,
            surname: tokenParsed.family_name,
            mail: tokenParsed.email,
            birthDate: null,
            job: null,
        }),
    })
        .then((res) => {
            // console.log('res');console.log(res);
            return res.json();
        })
        .then((res) => {
            // console.log('res');console.log(res);
        })
        .catch((err) => {
            console.log("err");
            console.log(err);
        });
};

const update_user = (token, tokenParsed) => {
    // console.log('token');console.log(tokenParsed);
    console.log("update user");
    fetch("/api/users/lastConnexion", {
        method: "PUT",
        headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        // body: JSON.stringify({
        //     name: tokenParsed.given_name,
        //     surname: tokenParsed.family_name,
        //     mail: tokenParsed.email,
        //     visible: 1,
        // }),
    })
        .then((res) => {
            // console.log('res');console.log(res);
            return res.json();
        })
        .then((res) => {
            // console.log('res');console.log(res);
        })
        .catch((err) => {
            console.log("err");
            console.log(err);
        });
};

export default Replicated;
