// LineChartComponent.js
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LineChartComponent_simple = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="date" />
        <Tooltip />
        <Legend />
        <Line type="monotone" yAxisId="left" name="Energie" dataKey="energySaved" stroke="#346840" strokeWidth={2} dot={false} />
        <Line type="monotone" yAxisId="right" name="Eau" dataKey="waterSaved" stroke="#106198"  strokeWidth={2} dot={false}/>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent_simple;
