import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import luniwave_icon from "../img/Luniwave_icon.png"
import Translation from "./Translation";


const NavbarUser = () => {
    return (
        <div className="navbar-hotel-2">
            <div className="logo-section-navbarhotel">
                <img src={luniwave_icon} alt="Logo" className="navbar-logo-2" />
                <span className="hotel-name"><Translation tag={"Hotel_Access"}/></span>
            </div>
            <Link to="/user/hotels">
                <span className="more-arrow-3">→</span>
            </Link>
        </div>
    );
};

export default NavbarUser;
