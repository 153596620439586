import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faStar,
    faPlus,
    faHeart,
    faCheck,
    faGraduationCap,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

function Notation(props) {
    const [nbStar, setNbStar] = useState([]);
    // let notation = props.nbStars

    let newNotation = [0, 0, 0, 0, 0];
    if (props.nbStar > 0 && props.nbStar <= 5) {
        newNotation = [];
        for (var i = 1; i <= Math.floor(props.nbStar); i++) {
            newNotation = [...newNotation, 1];
        }
        for (var i = Math.floor(props.nbStar); i < 5; i++) {
            newNotation = [...newNotation, 0];
        }
    } else {
        newNotation = [0, 0, 0, 0, 0];
    }
    // const setto = newNotation
    // setNbStar(setto)

    return (
        <div className="nbEtoile">
            {newNotation.map(
                (note, ind) =>
                    note ? (
                        <FontAwesomeIcon
                            key={ind}
                            className="active starNot"
                            onClick={() =>
                                props.modifyRight === 0
                                    ? ""
                                    : props.handleChange(ind + 1)
                            }
                            icon={faStar}
                        />
                    ) : (
                        <FontAwesomeIcon
                            key={ind}
                            onClick={() =>
                                props.modifyRight === 0
                                    ? ""
                                    : props.handleChange(ind + 1)
                            }
                            className="inactive starNot"
                            icon={faStar}
                        />
                    )
                // {console.log(note);}
            )}

            {/* {newNotation.map((note, ind) => 
                console.log(note)
            )} */}
        </div>
    );
}

export default Notation;
