import React from "react";
import { Navigate, Route, redirect } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import Base from "./views/Base";
import Main from "./views/Home";
import PrivateRoute from "./auth/PrivateRoute";
import CentralizeRedirect from "auth/CentralizeRedirect";
import replicated from "auth/replicated";

// eslint-disable-next-line react-hooks/rules-of-hooks
//const { keycloak, initialized } = useKeycloak();

const routes = [
    {
        path: "/hotel/",
        element: <Base pageName="Home hôtel" viewID={"hotel"} />,
    },
    {
        path: "/settings/hotel/informations",
        element: <Base pageName="Mes réglages" viewID={"hotelInformations"} />,
    },
    
    {
        path: "/settings/hotel/rooms",
        element: <Base pageName="Gestion des chambres" viewID={"rooms_settings"} />,
    },
    {
        path: "/hotel/lunishower",
        element: <Navigate to="/hotel/lunishower/dashboard" replace />,
    },
    
    {
        path: "/hotel/lunishower/dashboard",
        element: <Base pageName="Tableau de bord" viewID={"dashboard"} />,
    },
    {
        path: "/hotel/lunishower/consommation",
        element: <Base pageName="Consommation" viewID={"consommation"} />,
    },
    {
        path: "/hotel/lunishower/economies",
        element: <Base pageName="Economies" viewID={"economies"} />,
    },
    {
        path: "/hotel/lunishower/rooms",
        element: <Base pageName="Les chambres" viewID={"rooms"} />,
    },
    {
        path: "/hotel/lunishower/rooms/:roomId",
        element: <Base pageName="Ma chambre" viewID={"room"} />,
    },
    {
        path: "/hotel/lunishower/donation",
        element: <Base pageName="Mes dons" viewID={"donation"} />,
    },
    {
        path: "/settings/hotel/access",
        element: (
            <Base pageName="Gestion des accès" viewID={"myAccess"} />
        ),
    },
    {
        path: "/settings/hotel/devices",
        element: (
            <Base pageName="Gestion des appareils" viewID={"devices"} />
        ),
    },
    {
        path: "/user/group/ajouter",
        element: <Base pageName="Ajouter un groupe" viewID={"addGroup"} />,
    },
    {
        path: "/user/hotels/ajouter",
        element: <Base pageName="Ajouter un hôtel" viewID={"addHotel"} />,
    },
    {
        path: "/user/informations",
        element: (
            <Base pageName="Mes informations" viewID={"personalInformations"} />
        ),
    },
    {
        path: "/user/hotels",
        element: <Base pageName="Mes hôtels" viewID={"myHotels"} />,
    },
    {
        path: "/user/statistics",
        element: <Base pageName="Mes statistiques" viewID={"statistics"} />,
    },
    {
        path: "/user/preferences",
        element: <Base pageName="Mes préférences" viewID={"preferences"} />,
    },
    {
        path: "/user/accessAPI",
        element: <Base pageName="Accès API" viewID={"accessAPI"} />,
    },
    {
        path: "/inscription",
        element: <Base pageName="Inscription" viewID={"inscription"} />,
    },

    {
        path: "/redirect",
        // action: async ({ request }) => {
        //     console.log("action");
        //     return true;
        // },
        // loader: ({ params }) => {
        //     //const { keycloak, initialized } = useKeycloak();

        //     console.log("loader");
        //     //return redirect("/hotel");
        //     return true;
        //     //return false;
        // },
        element: <CentralizeRedirect />,
    },
    { path: "/", element: <Base pageName="Mon hôtel" viewID={"main"} /> },
    { path: "*", element: <Navigate to="/" replace /> },
    // Add more routes as needed
    // ...
];

export default routes;
