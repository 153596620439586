import React from 'react';
import Translation from 'utils/Translation';

const SmallCard = (props) => {
  return (
    <div className="stat-small-card">
      <div className="stat-small-title"><Translation tag={props.title}/></div>
      <div className="stat-small-value">{props.value} <span className="stat-small-unit">{props.unit}</span></div>
    </div>
  );
};

export default SmallCard;
