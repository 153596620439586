import React from 'react';
import { Link } from 'react-router-dom';
import bed from "../img/bed.svg"
import Translation from 'utils/Translation';

function RoomCard(props) {

  return (
    <Link to={`/hotel/lunishower/rooms/${props.number}`} className="room-card">
        <div className="room-icon"> <img src={bed} alt="bed"></img></div>
            <div className='RoomcardElem'>
                <span className='TopRoomCardText'>N°<br/></span>
                <span className='BottomRoomCardText'>{props.number}</span>
            </div>
            <div className='RoomcardElem'>
                <span className='TopRoomCardText'><Translation tag={"Floor"}/><br/></span>
                <span className='BottomRoomCardText'>{props.floor}</span>
            </div>
            <div className='RoomcardElem'>
                <span className='TopRoomCardText'>Tag<br/></span>
                <span className='BottomRoomCardText'>{props.tag}</span>
            </div>  
            <div className='RoomcardElem'>
                <span className='TopRoomCardText'><Translation tag={"Room_savings"}/><br/></span>
                <span className='BottomRoomCardText'>{Math.round(Number(props.waterSavings.replace(/\s/g, ''))/10)*10} L</span>

            </div>
            <div className='RoomcardElem'>
                <span className='TopRoomCardText'><Translation tag={"Gain"}/><br/></span>
                <span className='BottomRoomCardText'>{props.moneySavings} €</span>
            </div> 
            <span className="more-arrow-2">→</span>
            {/* <Link to={props.link} className="room-link">Lien</Link> */}
    </Link>
  );
}

export default RoomCard;
