import React, { useState, useEffect } from 'react';
import Translation from "utils/Translation";
import RoomCard from 'component/Roomcard';
import bdd_room from "BDD/bdd_room.json";
import bdd_prod from "BDD/bdd_prod.json";
import bdd from "BDD/bdd.json";
import BreadCrumb from 'component/Breadcrumb';

const Rooms = () => {
  const [roomsEnergySavings, setRoomsEnergySavings] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // État pour la barre de recherche
  const [selectedFloor, setSelectedFloor] = useState(''); // État pour le sélecteur d'étage
  const Search =String(<Translation tag={"Search"}/>);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  // Cette fonction trouve le numéro de produit pour une chambre donnée
  const findProductNumber = (rId, bddProd) => {
    const product = bddProd.find(product => product.lRId === rId);
    return product ? product.lNumProduct : null;
  };

  // Cette fonction calcule la somme des consommations d'eau pour un produit donné
  const calculateWaterSavings = (lNumProduct, bdd) => {
    return bdd
      .filter(shower => shower.shNUM_PRODUIT === lNumProduct)
      .reduce((total, shower) => total + shower.shCONSO_DOUCHE, 0);
  };
  const calculateMoneySavings = (lNumProduct, bdd) => {
    return bdd
      .filter(shower => shower.shNUM_PRODUIT === lNumProduct)
      .reduce((total, shower) => total + 0.24*((250*(40-15)*1.161/1000)-(shower.shCONSO_DOUCHE * (shower.shTEMP_DOUCHE - 15) * 1.161) / 1000), 0);
  };

  // Map sur les données de la chambre pour créer les RoomCards
  const filteredAndSortedRooms = bdd_room
  .filter(room => room.rRoomNumber.includes(searchTerm)) // Filtre par numéro de chambre
  .filter(room => selectedFloor === '' || room.rFloor === selectedFloor) // Filtre par étage si un étage est sélectionné
  .sort((a, b) => a.rRoomNumber - b.rRoomNumber) // Trie par numéro de chambre de manière croissante
  .map(room => {
    const roomEnergySaving = roomsEnergySavings.find(saving => saving.rId === room.rId);
    const energySavings = roomEnergySaving ? roomEnergySaving.energySavings : 0;
    const moneySavings = Math.round(calculateMoneySavings(findProductNumber(room.rId, bdd_prod), bdd)); // Conversion des économies d'énergie en argent
    const waterSavings = calculateWaterSavings(findProductNumber(room.rId, bdd_prod), bdd); // Calcul des économies d'eau

    return (
      <RoomCard
        key={room.rId}
        number={room.rRoomNumber}
        floor={room.rFloor}
        tag={room.Tag}
        waterSavings={waterSavings.toLocaleString('fr-FR')}
        moneySavings={moneySavings.toLocaleString('fr-FR')}
        link="/" // Ajustez le lien si nécessaire
      />
    );
  });
  const floors = [...new Set(bdd_room.map(room => room.rFloor))].sort();

  // Affichage de la liste des RoomCards
  return (
    <div className="rooms">
      <BreadCrumb text="Rooms_title" />
      <h1 className="h1-page">
        <Translation tag={"Rooms_title"} />
      </h1>
      <h2 className="h2-page">
        <Translation tag={"Rooms_description"} />
      </h2>
      <div className="search-and-select">
      <input
        type="text"
        placeholder={Search}
        className="search-input"
        value={searchTerm} // Lie l'état searchTerm à l'entrée
        onChange={handleSearchChange} 
      />

      <select className="select-input" value={selectedFloor} onChange={(e) => setSelectedFloor(e.target.value)} defaultValue="">
        <option value=""><Translation tag={"Etages"}/></option>
        {floors.map(floor => (
          <option key={floor} value={floor}>{`Étage ${floor}`}</option>
        ))}
      </select>
      </div>
      <div>
      {filteredAndSortedRooms.length === 0 ? (
        <Translation tag={"No_rooms"}></Translation>
      ) : (
        filteredAndSortedRooms
      )}      </div>
    </div>
    
  );
};

export default Rooms;
