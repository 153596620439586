import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Button } from "react-bootstrap";

import { HotelContext } from "../context/HotelContext";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import hotel from '../img/Hotel.svg'
import Translation from "utils/Translation";
const HotelApercu = (props) => {
    const { hId, sethId } = useContext(HotelContext);
    const { keycloak, initialized } = useKeycloak();

    const [result, setResult] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [wantDelete, setWantDelete] = useState(false);
    const [refused, setRefused] = useState(false);

    const handleClick = (hId) => {
        setLoading(true);
        //const uid = keycloak.tokenParsed.sub;
        fetch("/api/users/lastHotel/" + hId, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                // Ca marche
                console.log(data);
                if (data.success == "OK") {
                    //setInvitations(data.hotels);
                    sethId(hId);
                    setLoading(false);
                } else {
                    setHasError(true);
                    setLoading(false);
                }

                // setHotels(data)
            })
            .catch((err) => {
                // Err
                console.log("err");
                console.log(err);
                setHasError(true);
                setLoading(false);
            });
    };

    const modifierInvitation = (status) => {
        console.log("acceptInvitation");
        console.log(status);
        setLoading(true);
        const uid = keycloak.tokenParsed.sub;
        fetch("/api/hotels/" + props.data.hId + "/invitations/status/", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                status: status,
            }),
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                // Ca marche
                console.log(data);
                if (data.success == "OK") {
                    //setInvitations(data.hotels);
                    setResult(data);
                    if (status == "REFUSED") {
                        setRefused(true);
                        //setWantDelete(false);
                    }
                    setLoading(false);
                } else {
                    setHasError(true);
                    setLoading(false);
                }

                // setHotels(data)
            })
            .catch((err) => {
                // Err
                console.log("err");
                console.log(err);
                setHasError(true);
                setLoading(false);
            });
    };

    return wantDelete ? (
        refused ? (
            <div className="cntContener">
                <Translation tag={"Refuse_invt"}/>
            </div>
        ) : (
            <div className="cntContener">
                <Translation tag={"Valid_decline_invt"}/>
                <Button onClick={() => modifierInvitation("REFUSED")}>
                    OUI
                </Button>{" "}
                <Button onClick={() => setWantDelete(false)}><Translation tag={"Back"}/></Button>
            </div>
        )
    ) : result ? (
        <div><Translation tag={"Accept_invt"}/></div>
    ) : (
        <div className="cntContener">
                <div className="room-icon"> <img src={hotel} alt="bed"></img></div>
                <div className='RoomcardElem'>
                    <span className='TopRoomCardText'><Translation tag={"Hotel_Name"}/><br/></span>
                    <span className='BottomRoomCardText'>{props.data.hName}</span>
                </div>
                <div className='RoomcardElem'>
                    <span className='TopRoomCardText'><Translation tag={"Address"}/><br/></span>
                    <span className='BottomRoomCardText'>{props.data.hAddress}</span>
                </div>
                {props.type == "aperçu" ? (
                            props.hotelType == "hotelsInGroup" ? (
                                <Button className="bouton_hotel_choice"
                                    onClick={() =>
                                        props.handleRemoveHotelFromGroup(
                                            props.data.hId
                                        )
                                    }
                                >
                                    Retirer
                                </Button>
                            ) : props.hotelType == "hotelsToAdd" ? (
                                <Button
                                    onClick={() =>
                                        props.handleAddHotelToGroup(
                                            props.data.hId
                                        )
                                    }
                                >
                                    Ajouter
                                </Button>
                            ) : (
                                <Link to="/hotel">
                                    <Button className="bouton_hotel_choice"
                                        onClick={() =>
                                            handleClick(props.data.hId)
                                        }
                                    >
                                        <Translation tag={"Select"}/>
                                    </Button>
                                </Link>
                            )
                        ) : (
                            <>
                                <Button className="bouton_hotel_choice"
                                    onClick={() =>
                                        modifierInvitation("ACCEPTED")
                                    }
                                >
                                    <Translation tag={"Accept_invitation"}/>
                                </Button>

                                <Button className="bouton_hotel_choice" onClick={() => setWantDelete(true)}>
                                <Translation tag={"Decline_invitation"}/>
                                </Button>
                            </>
                        )}
        </div>
    );
};

// HotelApercu.propTypes = {
//     data: PropTypes.shape({
//         hName: PropTypes.string,
//         hAddress: PropTypes.string,
//         hId: PropTypes.number,
//     }),
// };

export default HotelApercu;
