import React from 'react';
import { Link } from 'react-router-dom';
import don from "../img/Don.svg"
import download from "../img/dowlnoad.svg"
import Translation from 'utils/Translation';


function DonationCard(props) {
  return (
    <div className="room-card-2">
        <div className="room-icon"> <img src={don} alt="bed"></img></div>
            <div className='RoomcardElem'>
                <span className='TopRoomCardText'>N°<br/></span>
                <span className='BottomRoomCardText'>{props.number}</span>
            </div>
            <div className='RoomcardElem'>
                <span className='TopRoomCardText'><Translation tag={"Delivrance"} /><br/></span>
                <span className='BottomRoomCardText'>{props.date}</span>
            </div>
            <div className='RoomcardElem'>
                <span className='TopRoomCardText'><Translation tag={"Periode"} /><br/></span>
                <span className='BottomRoomCardText'>{props.periode}</span>
            </div>  
            <div className='RoomcardElem'>
                <span className='TopRoomCardText'><Translation tag={"water_give"} /><br/></span>
                <span className='BottomRoomCardText'>{props.liters} L</span>
            </div>
            <a href={props.link} className="room-link" target="_blank" rel="noopener noreferrer"><img className="imgdownload" src={download}/></a>
    </div>
  );
}

export default DonationCard;
