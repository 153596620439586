import { useKeycloak } from "@react-keycloak/web";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

const ConfirmationDeleteAccount = (props) => {
    const { keycloak, initialized } = useKeycloak();

    const [reponse, setReponse] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const makeInvisible = () => {
        setLoading(true);

        const link = "/api/users/";

        fetch(link, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: props.name,
                surname: props.surname,
                mail: props.mail,
                birthDate: props.birthDate,
                job: props.job,
                visible: 0,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                console.log(res);
                setHasError(false);
                setLoading(false);
                setReponse(res);
                setIsDeleted(true);
                keycloak.logout();
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };
    return isLoading ? (
        <div>Enregistrement en cours ...</div>
    ) : hasError ? (
        <div>
            Il y a eut une erreur lors de la suppression de votre compte
            <Button onClick={() => props.setWantDelete(false)}>Retour</Button>
        </div>
    ) : isDeleted ? (
        <div>Votre compte a été supprimé</div>
    ) : (
        <div>
            Êtes-vous sûr de vouloir supprimer votre compte?{" "}
            <Button onClick={() => makeInvisible()}>OUI</Button>{" "}
            <Button onClick={() => props.setWantDelete(false)}>NON</Button>
        </div>
    );
};

export default ConfirmationDeleteAccount;
