// //Avec Route Browser
import React, { useEffect, useState, createContext } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import logo from "./logo.svg";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import Base from "./views/Base.js";
import Main from "./views/Home.js";
import PrivateRoute from "./auth/PrivateRoute";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";

import { HotelContext, HotelContextProvider } from "./context/HotelContext";
import CentralizeRedirect from "auth/CentralizeRedirect";
import replicated from "auth/replicated";

const router = createBrowserRouter(routes);

const App = () => {
    const { keycloak, initialized } = useKeycloak();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (initialized) {
            setLoading(false);
        }
    }, [initialized]);

    // if (keycloak && keycloak.authenticated) {
    //     // Si keycloak est initialisé et que l'on est authentifié
    //     // On regarde si l'utilisitateur est dans la DB
    //     console.log("replicated ");

    //     replicated(keycloak.token, keycloak.tokenParsed);
    //     // localStorage.setItem("replicated",keycloak.tokenParsed.sub);
    // }

    return loading ? (
        <>Loading...</>
    ) : (
        <HotelContextProvider>
            <RouterProvider router={router} />
        </HotelContextProvider>

        //     <Routes>
        //         <Route path="/" element={<Home />} />
        //         <Route path="/about" element={<About />} />
        //         <PrivateRoute path="/private" element={<PrivateComponent />} />
        //         <Route path="*" element={<NotFound />} />
        //     </Routes>
        // </RouterProvider>
    );
};

// Example of a private component accessible only for authenticated users
const PrivateComponent = () => {
    return <h2>Private Component - Only accessible for authenticated users</h2>;
};

export default App;

//Après Route Browser

// import React, { useState, useEffect, createContext } from "react";
// import {
//     BrowserRouter,
//     Route,
//     Routes,
//     Navigate,
//     redirect,
// } from "react-router-dom";
// //import { createBrowserRouter, RouterProvider } from "react-router-dom";
// //import routes from "./routes.js";

// import logo from "./logo.svg";
// import "./App.css";

// import "bootstrap/dist/css/bootstrap.min.css";

// import Base from "./views/Base";
// import Main from "./views/Home";
// import PrivateRoute from "./auth/PrivateRoute";

// import { useKeycloak } from "@react-keycloak/web";

// import { HotelContext, HotelContextProvider } from "./context/HotelContext";
// import CentralizeRedirect from "auth/CentralizeRedirect";

// //const router = createBrowserRouter(routes);

// function App() {
//     const { keycloak, initialized } = useKeycloak();
//     return !initialized ? (
//         <>...</>
//     ) : (
//         <HotelContextProvider>
//             <BrowserRouter>
//                 <Routes>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/hotel"
//                         element={
//                             <Base pageName="Home hôtel" viewID={"hotel"} />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/settings/hotel/informations"
//                         element={
//                             <Base
//                                 pageName="Mes réglages"
//                                 viewID={"hotelInformations"}
//                             />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/settings/hotel/features"
//                         element={
//                             <Base
//                                 pageName="Les fonctionnalités"
//                                 viewID={"features"}
//                             />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/settings/hotel/access"
//                         element={
//                             <Base pageName="Mes accès" viewID={"myAccess"} />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/settings/hotel/collaborators"
//                         element={
//                             <Base
//                                 pageName="Mes collaborateurs"
//                                 viewID={"collaborators"}
//                             />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/user/hotels/ajouter"
//                         element={
//                             <Base
//                                 pageName="Ajouter un hôtel"
//                                 viewID={"addHotel"}
//                             />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/user/informations"
//                         element={
//                             <Base
//                                 pageName="Mes informations"
//                                 viewID={"personalInformations"}
//                             />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/user/hotels"
//                         element={
//                             <Base pageName="Mes hôtels" viewID={"myHotels"} />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/user/statistics"
//                         element={
//                             <Base
//                                 pageName="Mes statistiques"
//                                 viewID={"statistics"}
//                             />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/user/preferences"
//                         element={
//                             <Base
//                                 pageName="Mes préférences"
//                                 viewID={"preferences"}
//                             />
//                         }
//                     ></Route>
//                     <Route
//                         // @ts-ignore
//                         exact
//                         path="/user/accessAPI"
//                         element={
//                             <Base pageName="Accès API" viewID={"accessAPI"} />
//                         }
//                     ></Route>
//                     {/* <Route
//                         path="/redirect"
//                         element={
//                             <CentralizeRedirect
//                                 keycloak={keycloak}
//                             ></CentralizeRedirect>
//                         }
//                     /> */}
//                     {/* <Route
//                         path="/redirect"
//                         action={async ({ request }) => {
//                             // const data = await request.formData();
//                             // const newProject = await createProject(data);
//                             // it's common to redirect after actions complete,
//                             // sending the user to the new record
//                             console.log("action");
//                             return redirect(`/hotel`);
//                         }}
//                     /> */}
//                     <Route
//                         path="/"
//                         element={<Base pageName="Mon hôtel" viewID={"main"} />}
//                     ></Route>
//                     <Route path="*" element={<Navigate to="/" replace />} />
//                 </Routes>
//             </BrowserRouter>
//         </HotelContextProvider>
//     );
// }

// export default App;
