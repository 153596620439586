//import React from "react";
import React, { useContext, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Button, Col, FormGroup, Row } from "react-bootstrap";
import { Form, Link, Navigate } from "react-router-dom";
import ContenerAction from "utils/ContenerAction";
import InfoForm from "utils/InfoForm";
import HotelApercu from "component/HotelApercu";
import AjouterHotel from "./AjouterHotel";

import { HotelContext } from "context/HotelContext";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const Inscription = () => {
    //The different steps to finalise the inscription
    //Step 1: complete the date of birth and job
    //Step 2 (optional) : accepte the pending invitations if any and create a hotel if wanted
    //Step 3: ...
    const { keycloak, initialized } = useKeycloak();
    const { hId, sethId } = useContext(HotelContext);

    const [step, setStep] = useState(1);

    const [user, setUser] = useState([]);
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [mail, setMail] = useState(null);
    const [birthDate, setBirthDate] = useState(null);
    const [job, setJob] = useState(null);

    const [invitations, setInvitations] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [hasReponse, setHasReponse] = useState(false);
    const [reponse, setReponse] = useState({});

    const [createHotel, setCreateHotel] = useState(false);
    //const [hotelCreated, setHotelCreated] = useState(null);

    const navigate = useNavigate(); // Initialize the useHistory hook

    useEffect(() => {
        const fetchUser = () => {
            setLoading(true);
            // console.log(keycloak.token)
            //const uid = keycloak.tokenParsed.sub
            fetch("/api/users/", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    // console.log(response)
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    setLoading(false);
                    setUser(data.user);
                    setBirthDate(data.user.uBirthDate);
                    setJob(data.user.uJob);
                    setName(data.user.uName);
                    setSurname(data.user.uSurname);
                    setMail(data.user.uMail);
                })
                .catch((err) => {
                    setHasError(true);
                    setHasReponse(true);
                    setLoading(false);
                });
        };

        // console.log(hotel)
        // const
        fetchUser();

        //keycloak.authenticated && hId ? fetchHotel() : setHotel([])
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log(job);

        if (birthDate !== "" && job !== "") {
            saveReponse();
        } else {
            //console.log(hasError);
            setReponse({
                message: "Un des champs est vide",
            });
            //console.log(reponse);

            setHasError(true);
            setHasReponse(true);
        }
    };

    const saveReponse = () => {
        setLoading(true);
        const link = "/api/users/";

        fetch(link, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: name,
                surname: surname,
                mail: mail,
                birthDate: birthDate,
                job: job,
                visible: 1,
            }),
        })
            .then((res) => {
                // console.log(res)
                return res.json();
            })
            .then((res) => {
                // console.log('res error')
                // console.log(res.error)
                console.log(res);

                if (res.error !== undefined) {
                    setHasError(true);
                    setHasReponse(true);
                    setReponse(res);
                    setLoading(false);
                } else {
                    setHasError(false);
                    setLoading(false);
                    setReponse(res);
                    setHasReponse(true);
                    fetchInvitations();
                    setStep(step + 1);
                }
            })
            .catch((err) => {
                // console.log('err')
                // console.log(err)
                setHasReponse(true);
                setHasError(true);
                //setReponse(res);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (birthDate) {
            const initialDate = new Date(birthDate);
            const formattedDate = initialDate.toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
            setBirthDate(formattedDate);
        }
    }, [birthDate]);

    // useEffect(() => {
    //     if (hotelCreated) {
    //         console.log(hotelCreated);
    //         sethId(hotelCreated);
    //         navigate("/hotel");
    //     }
    // }, [hotelCreated]);

    const fetchInvitations = () => {
        console.log("fetchInvitations");
        const uid = keycloak.tokenParsed.sub;
        fetch("/api/hotels/invitations", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                // Ca marche
                console.log(data);
                if (data.success == "OK") {
                    setInvitations(data.hotels);
                    setLoading(false);
                } else {
                    setHasError(true);
                    setLoading(false);
                }

                // setHotels(data)
            })
            .catch((err) => {
                // Err
                console.log("err");
                console.log(err);
                setHasError(true);
                setLoading(false);
            });
    };

    return (
        <div>
            <h1>Finalisez votre inscription</h1>
            {step == 1 ? (
                <>Complétez votre profil</>
            ) : step == 2 ? (
                <>Ajoutez des hôtels</>
            ) : (
                <></>
            )}
            <br />
            {step == 1 ? (
                <>
                    <Form className="form formLwe" onSubmit={handleSubmit}>
                        {hasReponse ? (
                            // @ts-ignore
                            <InfoForm
                                status={hasError ? "NOK" : "OK"}
                                reponse={reponse}
                            />
                        ) : (
                            <></>
                        )}
                        <FormGroup>
                            <Row className="oneFormInput">
                                <Col xs="4" className="left">
                                    <label htmlFor="birthDate">
                                        Birth Date 2
                                    </label>
                                </Col>
                                <Col xs="8" className="left">
                                    <input
                                        type="date"
                                        name="birthDate"
                                        value={birthDate}
                                        className="lweInput"
                                        onChange={(e) =>
                                            setBirthDate(e.target.value)
                                        }
                                        id="birthDate"
                                        placeholder="2002-09-23"
                                    />
                                </Col>
                            </Row>
                            <Row className="oneFormInput">
                                <Col xs="4" className="left">
                                    <label htmlFor="job">Job</label>
                                </Col>
                                <Col xs="8" className="left">
                                    <input
                                        name="job"
                                        value={job}
                                        className="lweInput"
                                        onChange={(e) => setJob(e.target.value)}
                                        id="job"
                                        placeholder="Sustainable Development Manager"
                                    />
                                </Col>
                            </Row>
                            <ContenerAction>
                                <input
                                    type="submit"
                                    className="btnLwe btn btn-primary btnSave"
                                    value="Suivant"
                                    // disabled
                                />
                            </ContenerAction>
                        </FormGroup>
                    </Form>
                </>
            ) : step == 2 ? (
                <>
                    {invitations.length != 0 ? (
                        <>
                            <div>Invitations a un hotel</div>
                            {invitations.map((invitation) => (
                                <HotelApercu
                                    key={"hotel" + invitation.hId}
                                    data={invitation}
                                    type={"invitation"}
                                />
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                    {!createHotel ? (
                        <Button onClick={() => setCreateHotel(true)}>
                            Créer un hôtel
                        </Button>
                    ) : (
                        <AjouterHotel
                            type={"inscription"}
                            //setHotelCreated={setHotelCreated}
                        />
                    )}
                    <Link to={"/user/hotels"}>
                        <Button>Skip et terminer</Button>
                    </Link>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default Inscription;
