import Keycloak from "keycloak-js";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
// const keycloak = new Keycloak()
let initOptions = {
    // url: "http://localhost:8080/",
    url: "https://auth.luniwave.com",
    realm: "Luniwave",
    clientId: "react-auth",
    // onLoad: 'login-required',
    onLoad: "check-sso",
    silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
    silentCheckSsoFallback: false,
    // promiseType: "native",
};

// let keycloak = Keycloak(initOptions)
let keycloak = Keycloak(initOptions);
// let keycloak = keycloak.init(initOptions)
// console.log(keycloak)
export default keycloak;
