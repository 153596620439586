import React, { useContext, useEffect, useState } from "react";
//import { Redirect } from "react-router-dom";
import { Navigate, redirect } from "react-router-dom";
//import React from "react";
import replicated from "./replicated.js";
// import { browserHistory } from './react-router'
import { useKeycloak } from "@react-keycloak/web";
import { HotelContext } from "context/HotelContext";

const CentralizeRedirect = (props) => {
    //   if (authFails)
    //     return <Redirect to='/login'  />
    //   }
    const { keycloak, initialized } = useKeycloak();
    const { hId, sethId } = useContext(HotelContext);
    const [userStatus, setUserStatus] = useState(null);
    //const keycloak = props.keycloak;
    console.log("passe par redirect");

    // console.log('url retour');console.log(localStorage.getItem("redirectURL"));

    // var redirect;
    // if (localStorage.getItem("redirectURL")) {
    //     redirect = localStorage.getItem("redirectURL");
    // } else {
    //     redirect = "/";
    // }
    // window.location.href = localStorage.getItem("redirectURL")
    // <Redirect to={redirect} />

    // console.log('url redirect');console.log(keycloak);

    // if (keycloak && keycloak.authenticated) {
    //     // Si keycloak est initialisé et que l'on est authentifié
    //     // On regarde si l'utilisitateur est dans la DB
    //     console.log("replicated ");

    //     replicated(keycloak.token, keycloak.tokenParsed)
    //         .then((status) => {
    //             console.log(status);
    //             setUserStatus(status);
    //         })
    //         .then((status) => {
    //             console.log(userStatus);
    //             if (userStatus === "created") {
    //                 // User was created, redirect to /user/inscription
    //                 return <Navigate to="/user/informations" />;
    //             } else if (userStatus === "updated") {
    //                 // User was updated, redirect to /user/hotels
    //                 console.log("hotels");
    //                 return <Navigate to="/user/hotels" />;
    //             } else {
    //                 // Handle other cases or show a loading spinner
    //                 return <div>Loading...</div>;
    //             }
    //         })
    //         .catch((error) => {
    //             // Handle error if needed
    //         });

    //     //replicated(keycloak.token, keycloak.tokenParsed);

    //     //return <Navigate to="/user/hotels" />;
    // } else {
    //     return <div>Loading...</div>;
    // }

    // if (!keycloak || !keycloak.authenticated) {
    //     // Not authenticated, redirect to the home page
    //     return <Navigate to="/" />;
    // }

    // On remet l'ancien directory
    //props.history.push(redirect);

    //return "Un problème lors du retour";
    // return "REdirect to "+ redirect
    //return <Redirect to={redirect} />
    //return <Redirect to="http://localhost:3000/recherche" />;
    //return redirect("/login");
    //return redirect("/hotel");
    //return <>Redirection</>;

    useEffect(() => {
        if (keycloak && keycloak.authenticated) {
            replicated(keycloak.token, keycloak.tokenParsed, sethId)
                .then((status) => {
                    setUserStatus(status);
                })
                .catch((error) => {
                    // Handle error if needed
                });
        }
    }, [keycloak]);

    if (!keycloak || !keycloak.authenticated) {
        // Not authenticated, redirect to the home page
        return <Navigate to="/" />;
    }

    if (userStatus === "created") {
        // User was created, redirect to /user/informations
        return <Navigate to="/inscription" />;
    } else if (userStatus === "updated") {
        // User was updated, redirect to /user/hotels
        if (hId) {
            return <Navigate to="/hotel" />;
        } else {
            return <Navigate to="/user/hotels" />;
        }
    } else if (userStatus === "deleted") {
        return (
            <div>Your account has been deleted, contact the client service</div>
        );
    } else {
        // Handle other cases or show a loading spinner
        return <div>Loading...</div>;
    }
};

export default CentralizeRedirect;
