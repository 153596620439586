import { useKeycloak } from "@react-keycloak/web";
import FormHotel from "component/FormHotel";
import { HotelContext } from "context/HotelContext";
import React, { useContext, useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContenerSaaS from "utils/ContenerSaaS";
import Translation from "utils/Translation";
const Reglages = () => {
    const { keycloak, initialized } = useKeycloak();
    const { hId } = useContext(HotelContext);
    const [hotel, setHotel] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [reload, setReload] = useState(false);

    const fetchHotel = () => {
        setLoading(true);
        // console.log(keycloak.token)
        const uid = keycloak.tokenParsed.sub;
        fetch("/api/hotels/" + hId, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setLoading(false);
                setHotel(data.hotel);
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        // console.log(hotel)
        // const
        //fetchAccess()
        keycloak.authenticated && hId ? fetchHotel() : setHotel([]);
        setReload(false);
    }, [hId, reload]);

    return (
        <ContenerSaaS title="Hotel_Info" notMaxHeight>
            <div>
                {!keycloak.authenticated ? (
                    <div><Translation tag={"hotel_access"}/></div>
                ) : isLoading ? (
                    <div><Translation tag={"Loading"}/></div>
                ) : hasError ? (
                    <div><Translation tag={"Erreur"}/></div>
                ) : hotel.length == 0 ? (
                    <div><Translation tag={"No_hotel"}/></div>
                ) : (
                    <FormHotel
                        mode="update"
                        hotel={hotel}
                        setReload={setReload}
                    />
                )}
            </div>
        </ContenerSaaS>
    );
};

export default Reglages;
