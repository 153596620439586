import Slidebar from "./Slidebar";
import classnames from "classnames";
import { Link,useLocation } from "react-router-dom";
import Translation from "utils/Translation";

function ElemSlide_settings(props) {
    const location = useLocation(); // Utilisez useLocation pour obtenir le chemin d'accès actuel
    //const isActive = location.pathname === props.url; // Vérifiez si l'élément est actif
    const isActive = location.pathname.startsWith(props.url); // Vérifiez si l'élément est actif

    return (
        <Link to={props.url} className="link-style">
            <div className={classnames("icon-container", { 'select': isActive })}>
                <img src={props.img} alt="Logo" className="icon-button" />
            </div>
        </Link>

    );
}

export default ElemSlide_settings;
