import React from "react";
import Slidebar from "../component/Slidebar";
import Translation from "utils/Translation";
import { Col, Row } from "react-bootstrap";

const Main = () => {
  return <>
  <div className="Home_Page">
    <span className="h1-page"><Translation tag={"Construction"}/></span>

  </div>
  
  
  </>;
};

export default Main;
