import React, { useState, useEffect } from 'react';
import Slidebar from "../component/Slidebar";
import Translation from "utils/Translation";
import droplet from "../img/droplet.svg"
import cloud from "../img/Cloud.svg"
import money from "../img/money.svg"
import LineChartComponent from "component/Linecomponent";
import StatCard_simple from "component/Stat_card_simple";
import { Col, Row } from "react-bootstrap";
import bdd from "../BDD/bdd.json";
import { Link } from "react-router-dom";
import CertificateCard from 'component/certificatecard';
import SmallCard from "component/small_card"
import BreadCrumb from 'component/Breadcrumb';

const Consommation = () => {
  const [tempStartDate, setTempStartDate] = useState('');
  const [tempEndDate, setTempEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [isStartDateManuallySet, setIsStartDateManuallySet] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [stats, setStats] = useState({
    numberOfShowers: 0,
    averageConsumption: 0,
    averageTemperature: 0,
    totalLitres: 0
  });
useEffect(() => {
  if (!isStartDateManuallySet) {
    const initialStartDate = bdd.reduce((minDate, shower) => {
      const showerDate = shower.shTimeCreate.split(' ')[0];
      return minDate < showerDate ? minDate : showerDate;
    }, bdd[0]?.shTimeCreate.split(' ')[0] || new Date().toISOString().split('T')[0]);

    setTempStartDate(initialStartDate);
  }

  // Filtre les données de bdd.json entre les dates de début et de fin
  const filteredData = bdd.filter((shower) => {
    const showerDate = new Date(shower.shTimeCreate.split(' ')[0]);
    // Si startDate ou endDate n'est pas défini (empty string), la comparaison retournera `true` par défaut.
    return (!startDate || showerDate >= new Date(startDate)) &&
           (!endDate || showerDate <= new Date(endDate));
  });

  
  // Calcule le nombre total de douches à partir des données filtrées
  const numberOfShowers = filteredData.length;

  // Calcule la consommation totale et la température totale à partir des données filtrées
  const totalConsumption = filteredData.reduce((sum, record) => sum + record.shCONSO_DOUCHE, 0);
  const totalTemperature = filteredData.reduce((sum, record) => sum + record.shTEMP_DOUCHE, 0);

  // Calcule les moyennes en divisant par le nombre de douches si supérieur à 0
  const averageConsumption = numberOfShowers > 0 ? totalConsumption / numberOfShowers : 0;
  const averageTemperature = numberOfShowers > 0 ? totalTemperature / numberOfShowers : 0;

  // Calcule les moyennes pour chaque jour à partir des données filtrées
  const groupedData = filteredData.reduce((acc, current) => {
    const date = current.shTimeCreate.split(' ')[0]; // Extrait la date sans l'heure
    if (!acc[date]) {
      acc[date] = { sumTemp: 0, sumConso: 0, count: 0 };
    }
    acc[date].sumTemp += current.shTEMP_DOUCHE;
    acc[date].sumConso += current.shCONSO_DOUCHE;
    acc[date].count++;
    return acc;
  }, {});
  // Transforme l'objet groupedData en tableau et calcule la moyenne pour chaque jour
  const averages = Object.keys(groupedData).map((date) => {
    const { sumTemp, sumConso, count } = groupedData[date];
    return {
      date,
      avgTemp: count > 0 ? Math.round(sumTemp / count) : 0,
      avgConso: count > 0 ? Math.round(sumConso / count) : 0,
    };
  });

  // Trie les moyennes par date
  averages.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Met à jour les données du graphique et les statistiques
  setChartData(averages);
  setStats({
    numberOfShowers,
    averageConsumption: Math.round(averageConsumption),
    averageTemperature: Math.round(averageTemperature),
    totalLitres: Math.round(totalConsumption)
  });
}, [startDate, endDate]); // Dépendances de l'effet pour le recalcul lors du changement des dates
const handleApplyDates = () => {
  // Fonction pour mettre à jour startDate et endDate avec les valeurs temporaires
  if (tempStartDate && tempEndDate) {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setIsStartDateManuallySet(true); // Assurez-vous que cette logique est toujours d'actualité
  }
};
  return <>
  <div className="consommation">
    <BreadCrumb text="Conso_title" />

    <h1 className="h1-page">
      <Translation tag={"Conso_title"} />
    </h1>
     {/* Ajouter les sélecteurs de dates */}
     <div className="date-picker">
      <span><Translation tag={"Period"}/></span>
      <input
        type="date"
        className="date_style"
        value={tempStartDate}
        onChange={(e) => { setTempStartDate(e.target.value); setIsStartDateManuallySet(true); }}
        />
      <span><Translation tag={"To"}/></span>
      <input
        type="date"
        className="date_style"
        value={tempEndDate}
        onChange={(e) => setTempEndDate(e.target.value)}
      />
       <button className="button_valid_date" onClick={handleApplyDates}> <Translation tag={"validate"}></Translation></button>
    </div>
    
    <div className="Dashboard-part-2-bis">
        <div className="Chart_dashboard">
          <div className="chart-header">
            <h1 className="Title_graph"><Translation tag={"Consumption_period"}/></h1>
          </div>
          <LineChartComponent data={chartData} Temp="Temperature" Eau="Eau" TempName="avgTemp" EauName="avgConso" />
        </div>
        <div className="stat_simple">
          <StatCard_simple number={stats.totalLitres.toLocaleString('fr-FR')} text1="L"  text1Color="#FFFFFF"  > </StatCard_simple>
          <StatCard_simple number={Math.round(stats.totalLitres*(stats.averageTemperature - 15)*1.161/1000)} text1="kWh"  text1Color="#FFFFFF" > </StatCard_simple>
          <StatCard_simple number={Math.round(stats.totalLitres*(stats.averageTemperature - 15)*1.161/10000)} text1="Kg eCO2"  text1Color="#FFFFFF"  > </StatCard_simple>
        </div>
      </div>
      </div>
      <h1 className="h1-page">
        <Translation tag={"Statistique"} />
      </h1>
      <div>
      <div className="col_dashboard_number">
        <SmallCard title="Conso_moy"  value={stats.averageConsumption.toLocaleString('fr-FR')} unit="L" />
        <SmallCard title="Number_Shower"  value={stats.numberOfShowers.toLocaleString('fr-FR')} unit="" />
        <SmallCard title="Average_temp"  value={stats.averageTemperature.toLocaleString('fr-FR')} unit="°C" />
        </div>
    </div>
  </>;
};

export default Consommation ;
