import React from 'react';
import download from '../img/download.svg'

const CertificateCard = (props) => {
  return (
    <div className="certificate-card">
      <div className="certificate-info">
        <div className="certificate-title">Certificat n°{props.certificateNumber}</div>
        <i className="certificate-date">Emis le {props.dateIssued}</i>
      </div>
      <button className="download-button" onClick={props.onDownloadClick}>
        <img className="button-icon-image-download" src={download} alt="Button" />
      </button>
    </div>
  );
};

export default CertificateCard;
