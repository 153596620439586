import { useKeycloak } from "@react-keycloak/web";
import FormHotel from "component/FormHotel";
import HotelApercu from "component/HotelApercu";
import { HotelContext } from "context/HotelContext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

import { Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import ContenerAction from "utils/ContenerAction";
import ContenerSaaS from "utils/ContenerSaaS";
import ConfirmationDeleteGroup from "./ConfirmationDeleteGroup";
import InfoForm from "utils/InfoForm";

const AddGroup = (props) => {
    const { keycloak, initialized } = useKeycloak();

    const [group, setGroup] = useState([]); //group that we created
    const [gId, setGId] = useState(null); //ID of the group that is created or that is modified after creating it or modifing it

    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);

    const [hotelsInGroup, setHotelsInGroup] = useState([]); // the hotels that are already in the group
    const [hotelsToAdd, setHotelsToAdd] = useState([]); // all the hotels we can add to a group (the hôtels not in it)

    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [hasReponse, setHasReponse] = useState(false);
    const [reponse, setReponse] = useState([]);

    const [wantDelete, setWantDelete] = useState(false);

    const navigate = useNavigate(); // Initialize the useHistory hook

    function handleSubmit(event, isFromEffect = false) {
        if (!isFromEffect) {
            console.log("preventDefault");
            event.preventDefault(); // Prevent the default form submission behavior
        }
        console.log("handleSubmit");
        setLoading(true);

        fetch(
            "/api/groups/" + (props.type == "edit" ? props.groupEditable : ""),
            {
                method: props.type == "edit" ? "put" : "post",
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: name,
                    description: description,
                }),
            }
        )
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                setReponse(data);
                console.log(data);

                if (data.success == "OK") {
                    console.log("success");

                    setGroup(data.group);
                    setGId(data.group.gId);
                    if (props.type == "edit") {
                        console.log("edit");
                        props.setSubmit(false);
                    }
                    setHasReponse(true);
                    //addHotelsToGroup();
                } else {
                    setHasError(true);
                    setLoading(false);
                    setHasReponse(true);
                }
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    }

    const addHotelsToGroup = () => {
        console.log(hotelsInGroup);
        console.log(gId);
        if (gId) {
            fetch("/api/groups/" + gId + "/hotels/", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    hotelsToAdd: hotelsInGroup,
                    hotelsToRemove: hotelsToAdd,
                }),
            })
                .then((response) => {
                    // console.log(response)
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    if (data.success == "NO") {
                        setHasError(true);
                        setLoading(false);
                    } else if (data.success == "OK") {
                        setLoading(false);
                        if (props.type == "edit") {
                            console.log("edit");
                            props.setGroupEditable(null);
                        }

                        // Redirect to /user/hotels after hotels have been added
                        navigate("/user/hotels");
                    }
                })
                .catch((err) => {
                    console.log("err");
                    console.log(err);
                    setHasError(true);
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        // Call the addHotelsToGroup function only when gId is defined
        console.log("useEffect pour gId");
        if (gId) {
            addHotelsToGroup();
        }
    }, [gId]);

    const getGroup = () => {
        setLoading(true);
        fetch("/api/groups/" + props.groupEditable, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                console.log(data);
                if (data.success == "NO") {
                    setHasError(true);
                    setLoading(false);
                } else if (data.success == "OK") {
                    setName(data.group.gName);
                    setDescription(data.group.gDescription);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };

    const getHotelsToAdd = () => {
        const link =
            props.type == "edit"
                ? "/api/groups/" + props.groupEditable + "/hotelsToAdd"
                : "/api/hotels/";
        fetch(link, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                console.log(data);
                if (data.success == "NO") {
                    setHasError(true);
                    setLoading(false);
                } else if (data.success == "OK") {
                    setLoading(false);
                    setHotelsToAdd(data.hotels);
                    //setName(data.group.gName);
                    //setDescription(data.group.gDescription);
                }
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };

    const getHotelsInGroup = () => {
        setLoading(true);

        fetch("/api/groups/" + props.groupEditable + "/hotels/", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                console.log(data);
                if (data.success == "NO") {
                    setHasError(true);
                    setLoading(false);
                } else if (data.success == "OK") {
                    //setLoading(false);
                    setHotelsInGroup(data.hotels);
                    getHotelsToAdd();
                }
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (props.type == "edit") {
            console.log("edit");
            getGroup();
        }
        getHotelsInGroup();
    }, [props.groupEditable]);

    useEffect(() => {
        props.submit ? handleSubmit(null, true) : console.log("nothing");
    }, [props.submit]);

    const handleAddHotelToGroup = (hotelId) => {
        // Find the hotel to be added from `indep` array
        const add = hotelsToAdd.find((hotel) => hotel.hId === hotelId);
        //console.log(add);

        if (add) {
            // Remove the hotel from `indep` array
            setHotelsToAdd(
                hotelsToAdd.filter((hotel) => hotel.hId !== hotelId)
            );
            //console.log(hotelsToAdd);

            setHotelsInGroup([...hotelsInGroup, add]);

            //console.log(hotelsInGroup);
        }
    };

    const handleRemoveHotelFromGroup = (hotelId) => {
        // Find the hotel to be added from `indep` array
        const remove = hotelsInGroup.find((hotel) => hotel.hId === hotelId);
        //console.log(remove);

        if (remove) {
            // Remove the hotel from `indep` array
            setHotelsInGroup(
                hotelsInGroup.filter((hotel) => hotel.hId !== hotelId)
            );
            //console.log(hotelsInGroup);

            setHotelsToAdd([...hotelsToAdd, remove]);

            //console.log(hotelsToAdd);
        }
    };

    return (
        <div>
            {!keycloak.authenticated ? (
                <div>Pour créer un goup, il faut se connecter</div>
            ) : isLoading ? (
                <div>Chargement...</div>
            ) : wantDelete ? (
                <ConfirmationDeleteGroup
                    setWantDelete={setWantDelete}
                    groupEditable={props.groupEditable}
                    setGroupEditable={props.setGroupEditable}
                ></ConfirmationDeleteGroup>
            ) : (
                <>
                    {hasReponse ? (
                        // @ts-ignore
                        <>
                            <InfoForm
                                status={hasError ? "NOK" : "OK"}
                                reponse={reponse}
                            />
                            HI
                        </>
                    ) : (
                        <></>
                    )}
                    <Form className="form formLwe" onSubmit={handleSubmit}>
                        <FormGroup>
                            <Row className="oneFormInput">
                                <Col xs="4" className="left">
                                    <label htmlFor="exampleEmail">Name</label>
                                </Col>
                                <Col xs="8" className="left">
                                    <input
                                        name="name"
                                        className="lweInput"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        id="name"
                                        placeholder="Groupe A"
                                        autoComplete="none"
                                    />
                                </Col>
                            </Row>
                            <Row className="oneFormInput">
                                <Col xs="4" className="left">
                                    <label htmlFor="Message">
                                        Description (optionnel)
                                    </label>
                                </Col>
                                <Col xs="8" className="left">
                                    <input
                                        name="description"
                                        className="lweInput"
                                        value={description}
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        id="description"
                                        placeholder="Description du groupe"
                                        autoComplete="none"
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                        {props.type == "create" ? (
                            // <ContenerAction>
                            //     <Link to={"/user/hotels"}>
                            //         <Button
                            //             onClick={() =>
                            //                 props.setGroupEditable(null)
                            //             }
                            //         >
                            //             Annuler
                            //         </Button>
                            //     </Link>

                            //     <input
                            //         type="submit"
                            //         className="btnLwe btn btn-primary btnSave"
                            //         value={
                            //             props.type == "edit"
                            //                 ? "Enregistrer"
                            //                 : "Créer"
                            //         }
                            //         //value="enregistrer"
                            //         // disabled
                            //     />
                            // </ContenerAction>
                            <ContenerAction>
                                <Link to={"/user/hotels"}>
                                    <Button
                                        onClick={() =>
                                            props.type === "edit"
                                                ? props.setGroupEditable(null)
                                                : ""
                                        }
                                    >
                                        Annuler
                                    </Button>
                                </Link>
                                <Button
                                    type="submit"
                                    className="btnLwe btn btn-primary btnSave"
                                >
                                    Créer
                                </Button>
                            </ContenerAction>
                        ) : (
                            <></>
                        )}
                    </Form>
                    Hôtels au sein du groupe :
                    {hotelsInGroup.length == 0 ? (
                        <div className="cntContener">
                            Pas d'hôtel dans ce groupe
                        </div>
                    ) : (
                        hotelsInGroup.map((hotel) => (
                            <HotelApercu
                                key={"hotel" + hotel.hId}
                                data={hotel}
                                type={"aperçu"}
                                hotelType={"hotelsInGroup"}
                                handleAddHotelToGroup={handleAddHotelToGroup}
                                handleRemoveHotelFromGroup={
                                    handleRemoveHotelFromGroup
                                }
                            />
                        ))
                    )}
                    Les hôtels que vous pouvez ajouter:
                    {hotelsToAdd.length == 0 ? (
                        <div className="cntContener">
                            Vous n'avez pas d'autre hôtel
                        </div>
                    ) : (
                        hotelsToAdd.map((hotel) => (
                            <HotelApercu
                                key={"hotel" + hotel.hId}
                                data={hotel}
                                type={"aperçu"}
                                hotelType={"hotelsToAdd"}
                                handleAddHotelToGroup={handleAddHotelToGroup}
                                handleRemoveHotelFromGroup={
                                    handleRemoveHotelFromGroup
                                }
                            />
                        ))
                    )}
                    {props.type == "create" ? (
                        <></>
                    ) : (
                        <Button onClick={() => setWantDelete(true)}>
                            Supprimer le groupe
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

export default AddGroup;
