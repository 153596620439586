// LineChartComponent.js
import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Translation from 'utils/Translation';

const LineChartComponent = (props) => {
  let minTemp = undefined;
  let maxTemp = undefined;
  let minConso = undefined;
  let maxConso = undefined;

// Parcourez toutes les entrées de données pour trouver les valeurs min et max si les données existent
props.data.forEach(entry => {
  if (entry && entry[props.TempName] !== undefined) {
    if (minTemp === undefined || entry[props.TempName] < minTemp) {
      minTemp = entry[props.TempName];
    }
    if (maxTemp === undefined || entry[props.TempName] > maxTemp) {
      maxTemp = entry[props.TempName];
    }
  }
  if (entry && entry[props.EauName] !== undefined) {
    if (minConso === undefined || entry[props.EauName] < minConso) {
      minConso = entry[props.EauName];
    }
    if (maxConso === undefined || entry[props.EauName] > maxConso) {
      maxConso = entry[props.EauName];
    }
  }
});

  
  return (
    <ResponsiveContainer width="100%" height={300}>

      <LineChart
        width={500}
        height={300}
        data={props.data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="date" />
        <Tooltip />
        <YAxis yAxisId="left" domain={[Math.floor(minTemp/10)*10,Math.ceil(maxTemp/10)*10]}/>
        <YAxis yAxisId="right" orientation="right"domain={[Math.floor(minConso/10)*10,Math.ceil(maxConso/10)*10]} />
        <Legend />
         <Line type="monotone" yAxisId="left" name={<Translation tag={props.Temp}/>} dataKey={props.TempName} stroke="#346840" strokeWidth={2} dot={false} />
         <Line type="monotone" yAxisId="right" name={<Translation tag={props.Eau}/>} dataKey={props.EauName} stroke="#106198"  strokeWidth={2} dot={false}/>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
