import Slidebar from "./Slidebar";
import classnames from "classnames";
import { Link,useLocation } from "react-router-dom";
import Translation from "utils/Translation";

function ElemSlide(props) {
    const location = useLocation(); // Utilisez useLocation pour obtenir le chemin d'accès actuel
    //const isActive = location.pathname === props.url; // Vérifiez si l'élément est actif
    const isActive = location.pathname.startsWith(props.url); // Vérifiez si l'élément est actif

    return (
        <Link to={props.url}>
            <div className={classnames("elemSlide", { 'select': isActive })}> {/* Utilisez la condition pour appliquer la classe 'select' */}
                <Translation tag={props.name} />
            </div>
        </Link>
    );
}

export default ElemSlide;
