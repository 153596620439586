import React, { createContext, useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

const HotelContext = createContext();

const HotelContextProvider = ({ children }) => {
    // the value that will be given to the context
    const [hId, sethId] = useState(null);
    const [lang, setLang] = useState("en");
    const { keycloak, initialized } = useKeycloak();
    if (hId == null && localStorage.getItem('hId')!=null) {
        sethId(localStorage.getItem('hId'));
    }
    useEffect(() => {
        if (hId != null) {
            localStorage.setItem('hId', hId);
        }else
        {
            localStorage.removeItem('hId');
        }
    },[hId])

    // fetch a user from a fake backend API
    // useEffect(() => {
    //     const fetchHotel = () => {
    //         console.log(keycloak.token)
    //         const uid = keycloak.tokenParsed.sub
    //         fetch('/api/gerants/' + uid + '/hotels', {
    //             method: 'get',
    //             headers: {
    //                 Authorization: 'Bearer ' + keycloak.token,
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //             },
    //         })
    //             .then((response) => {
    //                 console.log(response)
    //                 return response.json()
    //             })
    //             .then((data) => {
    //                 console.log(data)
    //                 // setd(1)
    //             })
    //             .catch((error) => console.log('An error occured'))
    //     }

    //     // const

    //     keycloak.authenticated ? fetchHotel() : sethId(null)
    // }, [initialized])

    return (
        // the Provider gives access to the context to its children
        <HotelContext.Provider value={{ hId, sethId, lang, setLang }}>
            {children}
        </HotelContext.Provider>
    );
};

export { HotelContext, HotelContextProvider };
