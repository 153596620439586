import React from 'react';
import { Link } from 'react-router-dom';
import Translation from 'utils/Translation';

function BreadCrumb(props) {
    return (
        <div className="breadcrumb">
            <Link to="/hotel"><Translation tag={"Your_hotel"} /></Link> /
            <Link to="/hotel/Lunishower">LuniShower</Link> / 
            <div className="last_elem"> <Translation tag={props.text} /></div>
            
        </div>
    )
}

export default BreadCrumb;
