// StatCard.js
import React from 'react';

function StatCard_simple(props) {
  return (
    <div className="stat-card-simple" style={{ color: props.text1Color }}>

      <div style={{ color: props.text1Color }}>
        <span className="stat-number">{props.number}</span> <span className="stat-text1">{props.text1}</span>   
      </div>
      
    </div>
  );
}

export default StatCard_simple;
