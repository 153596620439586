import React, { useState, useEffect } from 'react';
import Slidebar from "../component/Slidebar";
import Translation from "utils/Translation";
import droplet from "../img/droplet.svg"
import cloud from "../img/Cloud.svg"
import money from "../img/money.svg"
import LineChartComponent_simple from "component/Linecomponent_simple";
import StatCard from "component/Stat_card";
import { Col, Row } from "react-bootstrap";
import bdd from "../BDD/bdd.json";
import { Link } from "react-router-dom";
import BreadCrumb from 'component/Breadcrumb';
import CertificateCard from 'component/certificatecard';
import bdd_don from '../BDD/bdd_don.json'




const Dashboard = () => {
  const formatDate = (dateString) => {
    const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  const handleDownload = () => {
    console.log('Téléchargement du certificat...');
    // Implémentez la logique de téléchargement ici
  };

  const [chartData, setChartData] = useState([]);
  const [stats, setStats] = useState({
    numberOfShowers: 0,
      totalWaterSaved: 0,
      totalEnergySaved: 0,
  });
  const currentDate = new Date(); // Obtenez la date actuelle
  currentDate.setMonth(currentDate.getMonth() - 1); // Soustrayez 1 mois

  const [startDate, setStartDate] = useState(currentDate.toISOString().split('T')[0]); 
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    const standardShowerLitres = 120;
    const standardShowerTemp = 40;
    let waterSavings = 0;
    let energySavings = 0;
    let objectivesCount = 0;
    let objectivesTotal = 0;
    const dailySums = {};

    const filteredData = bdd.filter((shower) => {
      const showerDate = new Date(shower.shTimeCreate.split(' ')[0]);
      return (!startDate || showerDate >= new Date(startDate)) &&
             (!endDate || showerDate <= new Date(endDate));
    });

    filteredData.forEach(shower => {
      const showerDate = shower.shTimeCreate.split(' ')[0];
      
      // Vérifie si l'entrée pour ce jour existe déjà dans dailySums
      if (!dailySums[showerDate]) {
        dailySums[showerDate] = {
          waterSaved: 0,
          energySaved: 0,
        };
      }
    
      // Ajoutez les économies d'eau et d'énergie à l'entrée correspondante de dailySums
      const showerWaterSaving = standardShowerLitres - shower.shCONSO_DOUCHE;
      const showerEnergySaving = (standardShowerLitres * (standardShowerTemp - 15) * 1.161 / 1000) -
                                  (shower.shCONSO_DOUCHE * (shower.shTEMP_DOUCHE - 15) * 1.161 / 1000);
      dailySums[showerDate].waterSaved += showerWaterSaving;
      dailySums[showerDate].energySaved += showerEnergySaving;
    });
    bdd.forEach(shower => {
      const showerDate = shower.shTimeCreate.split(' ')[0];
      
    
      // Ajoutez les économies d'eau et d'énergie à l'entrée correspondante de dailySums
      const showerWaterSaving = standardShowerLitres - shower.shCONSO_DOUCHE;
      const showerEnergySaving = (standardShowerLitres * (standardShowerTemp - 15) * 1.161 / 1000) -
                                  (shower.shCONSO_DOUCHE * (shower.shTEMP_DOUCHE - 15) * 1.161 / 1000);
      waterSavings += showerWaterSaving;
      energySavings += showerEnergySaving;
    });
    const chartDataWithSums = Object.entries(dailySums).map(([date, sums]) => ({
      date,
      waterSaved: sums.waterSaved,
      energySaved: Math.round(sums.energySaved),
    }));
  
    // Trie les données pour le graphique par date
    chartDataWithSums.sort((a, b) => new Date(a.date) - new Date(b.date));

    
    // Met à jour les données du graphique et les statistiques
    setChartData(chartDataWithSums);
    setStats({
      numberOfShowers: bdd.length,
      totalWaterSaved: Math.round(waterSavings),
      totalEnergySaved: Math.round(energySavings),
    });
  }, []);// Dépendances de l'effet pour le recalcul lors du changement des dates
  

  return <>
  <div className="Dashboard">
    <BreadCrumb text="Dashboard_title" />
    <h1 className="h1-page">
      <Translation tag={"Dashboard_title"} />
    </h1>
    <div className="col_dashboard_number">
    <StatCard number={stats.totalWaterSaved.toLocaleString('fr-FR')} text1="L"  text1Color="#FFFFFF" text2="Water_savings" icon={droplet} > </StatCard>
    <StatCard number={stats.totalEnergySaved.toLocaleString('fr-FR')} text1="kgCO2"  text1Color="#FFFFFF" text2="CO2_equivalent" icon={cloud} > </StatCard>
    <StatCard number={Math.round(stats.totalEnergySaved*0.24).toLocaleString('fr-FR')} text1="€"  text1Color="#FFFFFF" text2="Money_savings" icon={money} > </StatCard>
    </div>
    <div className="Dashboard-part-2">
        <div className="Chart_dashboard">
          <div className="chart-header">
            <h1 className="Title_graph"><Translation tag={"Year_savings"} /></h1>
            <Link to="/hotel/lunishower/economies" >
              <button className="more-button" >
                <Translation tag={"See_more"} /> <span className="more-arrow">→</span>
              </button>
            </Link>
          </div>
          <LineChartComponent_simple data={chartData} />
        </div>
        <div className="Donation">
          <div className="chart-header">
            <h1 className="Title_graph"><Translation tag={"donation_title"} /></h1>
            <Link to="/hotel/lunishower/donation" >
              <button className="more-button-2" >
                <span className="more-arrow-2">→</span>
              </button>
            </Link>
          </div>
          {bdd_don.length > 0 ? (
             bdd_don.slice(0, 3).map((don) => (
              <CertificateCard certificateNumber={don.dNumber.toString()} dateIssued={formatDate(don.dDeliveryDate)} onDownloadClick={don.dlink}/>
            ))
          ) : (
            <p><Translation tag={"No_donation"}/></p>
        )}
        </div>
      </div>
    </div>
  </>;
};

export default Dashboard;
