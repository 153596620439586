import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Dropdown, Nav, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { Link } from "react-router-dom";

import { HotelContext } from "../context/HotelContext";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHotel, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import NavbarHotel from "./NavbarHotel";
import NavbarUser from "./NavbarUser";
import UserDropdown from "component/Dropdown_pres";
import Avatar from "../img/Avatar.svg"

function Navbar(props) {
    const { hId, sethId } = useContext(HotelContext);

    const { keycloak, initialized } = useKeycloak();
    const [userFetched, setUserfetched] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    // console.log(keycloak.tokenParsed)
    // console.log(keycloak.token)
    // console.log(keycloak)
    const dropdownItems = [
        { label: 'Réglages de mon compte', link: '/user/informations' },
        { label: 'Déconnexion', action: () => keycloak.logout() },
        // Ajoutez plus d'éléments ici
      ];

    useEffect(() => {
        const fetchUser = () => {
            setLoading(true);
            // console.log(keycloak.token)
            const uid = keycloak.tokenParsed.sub;
            fetch("/api/users/", {
                method: "get",
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    // console.log(response)
                    return response.json();
                })
                .then((data) => {
                    console.log(data.user.uName);
                    setLoading(false);
                    setUserfetched(data.user);
                })
                .catch((err) => {
                    setHasError(true);
                    setLoading(false);
                });
        };

        // const

        keycloak.authenticated ? fetchUser() : setUserfetched([]);

        //console.log(user);
    }, [hId]);

    function handleClick() {
        sethId(null);
    }

    return (
        <Row className="navbarPerso">
            <Col xs="6" className="left">
                {hId ? <NavbarHotel></NavbarHotel> : <NavbarUser></NavbarUser>}
            </Col>
            <Col xs="6" className="col-navbar-right">
                {!keycloak.authenticated ? (
                    <Button
                        onClick={() => {
                            // On sauvegarde l'url
                            keycloak.login({
                                redirectUri:
                                    window.location.origin + "/redirect",
                            });
                        }}
                    >
                        Se connecter
                    </Button>
                ) : isLoading ? (
                    <div>...</div>
                ) : hasError ? (
                    <div>Une erreur </div>
                ) : (
                    <UserDropdown username={`${userFetched.uName} ${userFetched.uSurname}`} img={Avatar} items={dropdownItems} />
                )}
            </Col>
            {/* <Col xs="6" className="right navBarBtn">
                {!keycloak.authenticated ? (
                    <Button
                        onClick={() => {
                            // On sauvegarde l'url
                            keycloak.login();
                        }}
                    >
                        Se connecter
                    </Button>
                ) : isLoading ? (
                    <div>...</div>
                ) : hasError ? (
                    <div>Une erreur </div>
                ) : (
                    <Nav
                        className="navbar-nav-hover align-items-lg-center"
                        navbar
                    >
                        <Dropdown>
                            <DropdownToggle>
                                <>
                                    <FontAwesomeIcon icon={faHotel} />
                                    <span className="nav-link-inner--text">
                                        {/* {console.log(hotel)} */}
            {/* {hotel.length === 0
                                            ? "Aucun hôtel sélectionné"
                                            : hotel.hName}
                                    </span>
                                </>
                            </DropdownToggle>
                            <DropdownMenu>
                                {hotel.length !== 0 ? (
                                    <DropdownItem
                                        onClick={() => sethId(null)}
                                        tag={Link}
                                        to={"/"}
                                    >
                                        Changer d'hôtel
                                    </DropdownItem>
                                ) : (
                                    <></>
                                )}
                                <DropdownItem
                                    onClick={() => keycloak.logout()}
                                    tag={Link}
                                    to={"#"}
                                >
                                    Déconnexion
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                )} */}
            {/* {keycloak.authenticated ? (
                    <>
                        <Nav
                            className="navbar-nav-hover align-items-lg-center"
                            navbar
                        >
                            <Dropdown nav>
                                <DropdownToggle>
                                    <>
                                        <FontAwesomeIcon icon={faUserCircle} />
                                        <span className="nav-link-inner--text">
                                            {/* {keycloak.idTokenParsed.name} 
                                            TEST
                                        </span>
                                    </>
                                </DropdownToggle>
                                {keycloak.authenticated ? (
                                    <DropdownMenu>
                                        <DropdownItem
                                            to="/landing-page"
                                            tag={Link}
                                        >
                                            Mon compte
                                        </DropdownItem>
                                        <DropdownItem
                                            to="/ambassadeurs"
                                            tag={Link}
                                        >
                                            Mode ambassadeur
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => keycloak.logout()}
                                            tag={Link}
                                            to={'#'}
                                        >
                                            Déconnexion
                                        </DropdownItem>
                                    </DropdownMenu>
                                ) : null}
                            </Dropdown>
                        </Nav>
                    </>
                ) : null} */}
            {/* </Col> */}
        </Row>
    );
}

export default Navbar;
