import { useKeycloak } from "@react-keycloak/web";
import FormUser from "component/FormUser";
import { HotelContext } from "context/HotelContext";
import React, { useContext, useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import ContenerSaaS from "utils/ContenerSaaS";

const PersonalSettings = () => {
    const { keycloak, initialized } = useKeycloak();
    //const { hId } = useContext(HotelContext)
    const [user, setUser] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [reload, setReload] = useState(false);

    useEffect(() => {
        const fetchUser = () => {
            setLoading(true);
            // console.log(keycloak.token)
            //const uid = keycloak.tokenParsed.sub
            fetch("/api/users/", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    // console.log(response)
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    setLoading(false);
                    setUser(data.user);
                })
                .catch((err) => {
                    setHasError(true);
                    setLoading(false);
                });
        };

        // console.log(hotel)
        // const
        fetchUser();
        setReload(false);

        //keycloak.authenticated && hId ? fetchHotel() : setHotel([])
    }, [reload]);

    return (
        <ContenerSaaS title="Mon_profil" notMaxHeight>
            <div className="Contenant">
                {!keycloak.authenticated ? (
                    <div>Pour voir vos informations, il faut se connecter</div>
                ) : isLoading ? (
                    <div>Chargement...</div>
                ) : hasError ? (
                    <div>Une erreur </div>
                ) : (
                    //To do: créer un form pour changer ses infos
                    <FormUser user={user} setReload={setReload} />
                )}
            </div>
        </ContenerSaaS>
    );
};

export default PersonalSettings;
