import { useKeycloak } from "@react-keycloak/web";
import { HotelContext } from "context/HotelContext";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import luniwave_icon from "../img/Luniwave_icon.png"
import param_icon from "../img/param.svg"
import droplet from "../img/droplet.svg"
import home from "../img/home.svg"
import circular from "../img/Circular.svg"
import { useLocation } from 'react-router-dom';





const NavBarHotel = () => {
    const location = useLocation();
    const isActive = location.pathname.includes('lunishower');
    const { hId, sethId } = useContext(HotelContext);

    const [hotel, setHotel] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);

    const { keycloak, initialized } = useKeycloak();
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const fetchHotel = () => {
        setLoading(true);
        // console.log(keycloak.token)
        // const uid = keycloak.tokenParsed.sub;
        fetch("/api/hotels/" + hId, {
            method: "get",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                console.log(data);
                //setLoading(false);
                setHotel(data.hotel);
                fetchSubscriptions();
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };

    const fetchSubscriptions = () => {
        //setLoading(true);
        // console.log(keycloak.token)
        //const uid = keycloak.tokenParsed.sub;
        fetch("/api/hotels/" + hId + "/subscriptions/", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setLoading(false);
                setSubscriptions(data.subscription);
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {

        if(keycloak.authenticated){
            fetchHotel()
        } 


    }, [hId]);

    function handleClick() {
        sethId(null);
    }

    return (
        <div>
            <div className="navbar-hotel">
                <div className="logo-section-navbarhotel">
                    <img src={luniwave_icon} alt="Logo" className="navbar-logo" />
                    <span className="hotel-name">{hotel.hName}</span>
                </div>
                <div className="icon-section">
                    <div className="First-button">
                        {/* <Link to="/hotel">
                        <Button className="navbar-icon-button"><img src={home} alt="Home" className="navbar-icon" /></Button>
                        </Link> */}
                        <Link to="/settings/hotel/informations">
                            <Button className="navbar-icon-button"><img src={param_icon} alt="Settings" className="navbar-icon" /></Button>
                        </Link>
                        <Link to="/hotel/lunishower/Dashboard">
                            <Button  className={`navbar-icon-button ${isActive ? 'navbar-icon-button-active' : 'navbar-icon-button-inactive'}`}><img src={droplet} alt="Water"       className={`navbar-icon ${isActive ? 'navbar-icon-active' : 'navbar-icon-inactive'}`} /></Button>
                        </Link>
                    </div>
                    </div>
                    <div className="End-button">
                        <Link to="/user/hotels">
                            <Button className="navbar-icon-button-end" onClick={() => handleClick()}><img src={circular} alt="Changehotel" className="navbar-icon-end" /></Button>
                        </Link>
                    </div>
                    
                    {/* <img src={currencyIcon} alt="Currency" className="navbar-icon" /> */}
                </div>
            {subscriptions.map((item) => {
                //console.log(item.fName);
                return <Button key={item.sId}>{item.fName}</Button>;
            })}
        </div>
    );
};

export default NavBarHotel;
