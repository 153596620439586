import Slidebar from "./Slidebar";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Translation from "utils/Translation";
import droplet from "../img/droplet.svg"
import React from "react";

function Logo(props) {
    return (
        <div className="logo-container">
            <div className="logo-image">
                <img className="Logo-svg" src={droplet} alt="Logo" />
            </div>
        <h1 className="logo-text"><Translation tag={props.name} /></h1>
    </div>
    );
}

export default Logo;
