import React, { useEffect, useState } from "react";

import { useKeycloak } from "@react-keycloak/web";
import ContenerSaaS from "../utils/ContenerSaaS";
import HotelApercu from "../component/HotelApercu";
import ContenerAction from "utils/ContenerAction";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Groups from "component/Groups";
import GroupInfos from "./GroupInfos";
import AddGroup from "./AddGroup";

const ChoiceHotel = () => {
    const { keycloak, initialized } = useKeycloak();

    const [hotels, setHotels] = useState([]);
    const [invitations, setInvitations] = useState([]);
    const [groups, setGroups] = useState([]);
    const [indep, setIndep] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [submit, setSubmit] = useState(false);

    const [groupEditable, setGroupEditable] = useState(null);

    const fetchInvitations = () => {
        console.log("fetchInvitations");
        const uid = keycloak.tokenParsed.sub;
        fetch("/api/hotels/invitations", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                // Ca marche
                console.log(data);
                if (data.success == "OK") {
                    setInvitations(data.hotels);
                    setLoading(false);
                } else {
                    setHasError(true);
                    setLoading(false);
                }

                // setHotels(data)
            })
            .catch((err) => {
                // Err
                console.log("err");
                console.log(err);
                setHasError(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        const fetchHotel = () => {
            setLoading(true);

            console.log(keycloak.tokenParsed);
            // console.log(keycloak.token)

            const uid = keycloak.tokenParsed.sub;
            fetch("/api/groups/hotels", {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    // console.log(response)
                    return response.json();
                })
                .then((data) => {
                    // Ca marche
                    console.log(data);
                    if (data.success == "OK") {
                        setGroups(data.groups);
                        setIndep(data.independent);
                        fetchInvitations();
                    } else {
                        setHasError(true);
                        setLoading(false);
                    }

                    // setHotels(data)
                })
                .catch((err) => {
                    // Err
                    console.log("err");
                    console.log(err);
                    setHasError(true);
                    setLoading(false);
                });
        };

        // const

        keycloak.authenticated ? fetchHotel() : setHotels([]);
    }, [initialized, groupEditable]);

    const elemBtn = keycloak.authenticated ? (
        groupEditable != null ? (
            <ContenerAction>
                <Link to={"/user/hotels"}>
                    <Button onClick={() => setGroupEditable(null)}>
                        Annuler
                    </Button>
                </Link>

                <Button onClick={() => setSubmit(true)}>Enregistrer</Button>
            </ContenerAction>
        ) : (
            <>
                <Link to={"/user/hotels/ajouter"}>
                    <Button>Ajouter un hôtel</Button>
                </Link>
                <Link to={"/user/group/ajouter"}>
                    <Button>Créer un groupe</Button>
                </Link>
            </>
        )
    ) : (
        <></>
    );

    return (
        <ContenerSaaS
            title={
                groupEditable != null
                    ? "Modifier mon groupe"
                    : "Mes_hotels"
            }
            subtitle={"liste_hotel"}
            // btn={elemBtn}
            notMaxHeight={false}
        >
            <div className="mainContener">
                {!keycloak.authenticated ? (
                    <div className="cntContener">
                        Pour accéder à vos hôtels, il faut se connecter
                    </div>
                ) : isLoading ? (
                    <div className="cntContener">Chargement...</div>
                ) : hasError ? (
                    <div className="cntContener">Une erreur </div>
                ) : groupEditable == null ? (
                    <>
                        {invitations.length != 0 ? (
                            <>
                                <div>Invitations a un hotel</div>
                                {invitations.map((invitation) => (
                                    <HotelApercu
                                        key={"hotel" + invitation.hId}
                                        data={invitation}
                                        type={"invitation"}
                                    />
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                        {groups.map((group) => (
                            <Groups
                                key={"group" + group.gId}
                                group={group}
                                setGroupEditable={setGroupEditable}
                            ></Groups>
                        ))}
                        {indep.map((hotel) => (
                            <HotelApercu
                                key={"hotel" + hotel.hId}
                                data={hotel}
                                type={"aperçu"}
                            />
                        ))}
                    </>
                ) : (
                    <AddGroup
                        groupEditable={groupEditable}
                        type={"edit"}
                        setGroupEditable={setGroupEditable}
                        submit={submit}
                        setSubmit={setSubmit}
                    ></AddGroup>
                )}
            </div>
        </ContenerSaaS>
    );
};

export default ChoiceHotel;
