import React, { useState, useContext } from "react";
import { Link } from "react-router-dom"; // Assurez-vous que ceci est importé
import keycloak from "auth/keycloak";
import { Button } from "react-bootstrap";
import { HotelContext } from "context/HotelContext";

function UserDropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const { lang, setLang } = useContext(HotelContext);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleItemClick = (item) => {
        if (item.action) {
            item.action(); // Si c'est une fonction, exécutez-la
        }
        // Pas besoin d'ailleurs de gérer la navigation ici,
        // car <Link> s'en charge si c'est un lien
        setIsOpen(false); // Fermez le menu déroulant après le clic
    };

    return (
        <>
            <div className="user-dropdown" onClick={toggleDropdown}>
                <div className="user-info">
                    <img src={props.img} className="avatar" />
                    <span className="username">{props.username}</span>
                    <span className="dropdown-icon">&#x25BC;</span>{" "}
                    {/* Symbole pour l'icône du dropdown */}
                </div>

                {isOpen && (
                    <ul className="dropdown-menu-2">
                        {props.items.map((item, index) => (
                            <li
                                key={index}
                                className="dropdown-item"
                                onClick={() => handleItemClick(item)}
                            >
                                {item.link ? (
                                    <Link
                                        to={item.link}
                                        className="dropdown-text"
                                    >
                                        {item.label}
                                    </Link>
                                ) : (
                                    <span>{item.label}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
}

export default UserDropdown;
