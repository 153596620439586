import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Translation from './Translation'

const ContenerSaaS = (props) => {
    let myStyle = {
        minHeight: props.notMaxHeight ? 'auto' : '80vh',
    }

    return (
        <div className="contenerSaas" style={myStyle}>
            <Row className="titleContener">
                <Col xs="6" className='titre_param'>
                    <div className="h1-page">
                    <Translation tag={props.title}></Translation>
                    </div>
                    <div className="h3-page">
                    <Translation tag={props.subtitle}></Translation>
                    </div>
                </Col>
                <Col xs="6" className="right">
                    {props.btn}
                </Col>
            </Row>
            {props.children}
        </div>
    )
}

export default ContenerSaaS
