import { useKeycloak } from "@react-keycloak/web";
import FormHotel from "component/FormHotel";
import { HotelContext } from "context/HotelContext";
import React, { useContext, useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import ContenerSaaS from "utils/ContenerSaaS";

const Reglages = (props) => {
    const { keycloak, initialized } = useKeycloak();

    const [hotel, setHotel] = useState({
        hName: "",
        hAddress: "",
        hPC: "",
        hCity: "",
        hCountry: "",
        hStars: 0,
        hNbRooms: "",
    });
    const [hasError, setHasError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    return (
        <ContenerSaaS title="Information sur votre hôtel" notMaxHeight>
            <div>
                {!keycloak.authenticated ? (
                    <div>Pour créer un hôtel, il faut se connecter</div>
                ) : isLoading ? (
                    <div>Chargement...</div>
                ) : hasError ? (
                    <div>Une erreur </div>
                ) : (
                    <FormHotel
                        mode="create"
                        hotel={hotel}
                        type={props.type}
                        //setHotelCreated={props.setHotelCreated}
                    />
                )}
            </div>
        </ContenerSaaS>
    );
};

export default Reglages;
