import React, { useContext, useEffect, useState } from "react";
import { HotelContext } from "context/HotelContext";

const translation = {
    Reglages: { fr: "Réglages", en: "Settings" },
    Hotel_Info: { fr: "Réglages de l'hôtel", en: "Hotel settings" },
    Mon_profil :{ fr: "Mon profil", en: "My Informations" },
    Mes_hotels :{ fr: "Mes hôtels", en: "My hotels" },
    liste_hotel:{ fr: "Voici la liste de l’ensemble des hôtels auxquels vous avez accès", en: "Here is the list of all the hotels to which you have access" },
    Your_hotel:{ fr: "Votre hôtel", en: "Your Hotel" },
    mes_hotels: { fr: "Mes hotels", en: "My Hotel" },
    validate: { fr: "Valider", en: "Validate" },
    No_donation: { fr: "Vous n'avez pas encore fait de dons", en: "You didn't make any donations yet" },
    No_rooms: { fr: "Aucune chambre trouvée", en: "No rooms finded" },
    Room_nb: { fr: "Chambre n°", en: "Room n°" },
    Dashboard_title: { fr: "Tableau de bord", en: "Dashboard" },
    Conso_title: { fr: "Consommations", en: "Consumptions" },
    Eco_title: { fr: "Economies", en: "Savings" },
    Rooms_title: { fr: "Mes chambres", en: "My rooms" },
    Statistique: { fr: "Statistiques", en: "Statistics" },
    See_more: { fr: "Voir plus", en: "See more" },
    Water_savings: { fr: "Eau économisé", en: "Water saved" },
    CO2_equivalent: { fr: "Equivalent CO2", en: "CO2 equivalent" },
    Money_savings: { fr: "Argent économisé", en: "Money saved" },
    Year_savings: { fr: "Economies sur l'année", en: "Annual savings" },
    Period: { fr: "Sur la période du", en: "Over the period from" },
    To: { fr: "au", en: "to" },
    Savings_period: { fr: "Economies sur la période", en: "Savings over the period" },
    Consumption_period: { fr: "Consommations sur la période", en: "Consumption over the period" },
    Percent_obj: { fr: "% d'objectif fixé", en: "% of target set"},
    Number_Shower: { fr: "Nombre de douches prises", en: "Number of showers taken"},
    Average_obj: { fr: "Objectif moyen", en: "Average target"},
    Average_temp: { fr: "Température moyenne", en: "Average temperature"},  
    Conso_moy: { fr: "Consommations moyenne / douche", en: "Average consumption / shower"},  
    Energie: { fr: "Energie (Wh)", en: "Energy (Wh)"},
    Eau: { fr: "Eau (L)", en: "Water (L)"},
    Temperature: { fr: "Température (°C)", en: "Temperature (°C)"},
    Etages: { fr: "Tous les étages", en: "All floors"},
    Search: { fr: "Rechercher", en: "Search"},
    Floor: { fr: "Etage", en: "Floor"},
    Room_savings: { fr: "Economies (Litres)", en: "Savings (Liters)"},
    Gain: { fr: "Gain (€)", en: "Earnings (€)"},
    Eau_reverse: { fr: "Eau reversé", en: "Water reversed"},
    People: { fr: "personnes", en: "people"},
    Water_accessed: { fr: "en + ont accès à l'eau", en: "access to water"},
    Certificats : { fr: "Certificats", en: "certificates"},
    Delivrance : { fr: "Date de délivrance", en: "Delivery date"},
    Periode : { fr: "Période", en: "Time period"},
    water_give : { fr: "Litres reversés", en: "Liters given"},
    hotel_access : { fr: "Pour accéder à votre hôtel, il faut se connecter", en: "To access your hotel, you need to log in"},
    Loading : { fr: "Chargement...", en: "Loading..."},
    Erreur : { fr: "Une erreur", en: "An error"},
    No_hotel : { fr: "Vous n'avez aucun hotel", en: "You don't have any hotels"},
    Saving : { fr: "Enregistrement en cours...", en: "Saving in progress..."},
    Delete : { fr: "L'hôtel a bien été supprimé", en: "The hotel has been removed"},
    Back_hotel : { fr: "Retour à mes hôtels", en: "Back to my hotels"},
    Delete_hotel : { fr: "Êtes vous sûr de vouloir supprimer l'hotel?", en: "Are you sure you want to delete the hotel?"},
    Cancel : { fr: "Annuler", en: "Cancel"},
    Delete_H : { fr: "Supprimer l'hôtel", en: "Delete hotel"},
    Hotel_Name : { fr: "Nom de l'hôtel", en: "Name of the hotel"},
    Intern_name : { fr: "Votre nomination interne", en: "Your internal name"},
    Coordonnees : { fr: "Coordonnées", en: "Contact details"},
    Hotel_place : { fr: "Localisation de l'hôtel", en: "Hotel location"},
    Address : { fr: "Adresse", en: "Address"},
    City : { fr: "Ville", en: "City"},
    PC : { fr: "Code postal", en: "Postal code"},
    Country : { fr: "Pays", en: "Country"},
    Stars : { fr: "Etoiles", en: "Stars"},
    Ranking : { fr: "Classement de l'hôtel", en: "Hotel ranking"},
    Back : { fr: "Retour", en: "Back"},
    Construction : { fr: "Page en construction...", en: "Page under construction..."},
    Identifiant : { fr: "Identifiant", en: "User ID"},
    Email : { fr: "votre adresse email", en: "Email address"},
    Email_invalid : { fr: "Email invalide", en: "Email address invalid"},
    Informations : { fr: "Informations", en: "Information"},
    About_you : { fr: "Sur vous", en: "About you"},
    First_name : { fr: "Prénom", en: "First name"},
    Last_name : { fr: "Nom", en: "Last name"},
    birthdate : { fr: "Date de naissance", en: "Birth date"},
    Job_title : { fr: "Nom de poste", en: "Job Title"},
    Refuse_invt : { fr: "Vous avez bien refusé l'invitation", en: "You declined the invitation"},
    Valid_decline_invt : { fr: "Vous êtes sur de vouloir décliner l'invitation?", en: "Are you sure you want to decline the invitation?"},
    Accept_invt : { fr: "Vous avez accepté l'invitation", en: "You have accepted the invitation"},
    Select : { fr: "Selectionner", en: "Select"},
    Accept_invitation : { fr: "Accepter l'invitation", en: "Accept invitation"},
    Decline_invitation : { fr: "Refuser l'invitation", en: "Decline invitation"},
    Hotel_Access : { fr: "Accéder à mes hôtels", en: "Access to my hotels"},

    Rooms_description: {
        fr: "Vue d’ensemble des chambres de l’hôtel",
        en: "Overview of hotel rooms",
    },
    donation_title: { fr: "Mes dons d'eau", en: "My water donations" },
    Donation_certificate: {
        fr: "Certificats de don d'eau",
        en: "Water donation certificates",
    },
    Learn_More_Water_Donation: {
        fr: "En savoir plus sur le don d’eau",
        en: "Find out more about donating water",
    },
    Made_Blue_Found: {
        fr: "L'association MadeBlue Fondation",
        en: "The MadeBlue Foundation",
    },
    Made_Blue_description: {
        fr: "Made Blue est une association dynamique engagée dans la réalisation de projets d'accès à l'eau potable, principalement dans des régions souffrant de stress hydrique permanent. Depuis sa création en 2014, Made Blue s'est concentrée sur la fourniture d'eau propre et l'hygiène, enregistrant un impact considérable : plus de 14,2 milliards de litres d'eau potable fournis et plus de 194 000 personnes ayant accès à l'eau. Made Blue est également innovant dans ses projets, tels que l'installation de distributeurs d'eau en Tanzanie et la promotion de l'autonomie des communautés locales dans la gestion de l'accès à l'eau. En outre, l'organisation contribue à la durabilité environnementale, avec une réduction significative des émissions de CO2.",
        en: "Made Blue is a dynamic association committed to implementing projects providing access to drinking water, mainly in regions suffering from permanent water stress. Since its creation in 2014, Made Blue has focused on providing clean water and hygiene, recording a considerable impact: more than 14.2 billion litres of drinking water supplied and more than 194,000 people with access to water. Made Blue is also innovative in its projects, such as installing water distributors in Tanzania and promoting the autonomy of local communities in managing access to water. The organisation also contributes to environmental sustainability, with a significant reduction in CO2 emissions.",
    },
};

const Translation = (props) => {
    const { lang } = useContext(HotelContext);

    // console.log("lang :" + lang);

    const supportedLang = ["fr", "en"];

    if (!supportedLang.includes(lang)) {
        // console.log("WARNING : Unsupported Lang");
        return "Unsupported Langage";
    }

    var toDisplay;
    if (translation[props.tag]) {
        toDisplay = translation[props.tag][lang];
    } else {
        toDisplay = props.tag;
    }
    return toDisplay;
};

export default Translation;
