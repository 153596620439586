import React, { useState, useEffect } from 'react';
import Translation from "utils/Translation";
import LineChartComponent from "component/Linecomponent";
import StatCard_simple from "component/Stat_card_simple";
import bdd from "../BDD/bdd.json";
import SmallCard from "component/small_card"
import BreadCrumb from 'component/Breadcrumb';



const Economies = () => {
  const [tempStartDate, setTempStartDate] = useState('');
  const [tempEndDate, setTempEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [isStartDateManuallySet, setIsStartDateManuallySet] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [stats, setStats] = useState({
    numberOfShowers: 0,
      totalWaterSaved: 0,
      totalEnergySaved: 0,
      averageObjectives: 0,
      percentObjectives:0,
  });

  useEffect(() => {
    if (!isStartDateManuallySet) {
      const initialStartDate = bdd.reduce((minDate, shower) => {
        const showerDate = shower.shTimeCreate.split(' ')[0];
        return minDate < showerDate ? minDate : showerDate;
      }, bdd[0]?.shTimeCreate.split(' ')[0] || new Date().toISOString().split('T')[0]);
  
      setTempStartDate(initialStartDate);
    }

    const standardShowerLitres = 120;
    const standardShowerTemp = 40;
  
    // Filtre les données de bdd.json entre les dates de début et de fin
    const filteredData = bdd.filter((shower) => {
      const showerDate = new Date(shower.shTimeCreate.split(' ')[0]);
      return (!startDate || showerDate >= new Date(startDate)) &&
             (!endDate || showerDate <= new Date(endDate));
    });
  
    // Initialise les variables pour les calculs
    let waterSavings = 0;
    let energySavings = 0;
    let objectivesCount = 0;
    let objectivesTotal = 0;
  
  
    // Calcule la moyenne des objectifs si des objectifs sont définis
    
    const dailySums = {};
    // Prépare les données pour le graphique
    filteredData.forEach(shower => {
      const showerDate = shower.shTimeCreate.split(' ')[0];
      
      // Vérifie si l'entrée pour ce jour existe déjà dans dailySums
      if (!dailySums[showerDate]) {
        dailySums[showerDate] = {
          waterSaved: 0,
          energySaved: 0,
        };
      }
    
      // Ajoutez les économies d'eau et d'énergie à l'entrée correspondante de dailySums
      const showerWaterSaving = standardShowerLitres - shower.shCONSO_DOUCHE;
      const showerEnergySaving = (standardShowerLitres * (standardShowerTemp - 15) * 1.161 / 1000) -
                                  (shower.shCONSO_DOUCHE * (shower.shTEMP_DOUCHE - 15) * 1.161 / 1000);
      dailySums[showerDate].waterSaved += showerWaterSaving;
      dailySums[showerDate].energySaved += showerEnergySaving;
      waterSavings += showerWaterSaving;
      energySavings += showerEnergySaving;
      if (shower.shOBJ_DOUCHE > 0) {
        objectivesCount++;
        objectivesTotal += shower.shOBJ_DOUCHE;
      }
    });
    const averageObjectives = objectivesCount > 0 ? objectivesTotal / objectivesCount : 0;
    // Calcule le pourcentage des objectifs atteints
    const percentObjectives = objectivesCount > 0 ? (objectivesCount / filteredData.length) * 100 : 0;
    // Transformez dailySums en un tableau pour chartDataWithSums
    const chartDataWithSums = Object.entries(dailySums).map(([date, sums]) => ({
      date,
      waterSaved: sums.waterSaved,
      energySaved: Math.round(sums.energySaved),
    }));
    
    // Trie les données pour le graphique par date
    chartDataWithSums.sort((a, b) => new Date(a.date) - new Date(b.date));

    
    // Met à jour les données du graphique et les statistiques
    setChartData(chartDataWithSums);
    setStats({
      numberOfShowers: filteredData.length,
      totalWaterSaved: Math.round(waterSavings),
      totalEnergySaved: Math.round(energySavings),
      averageObjectives: Math.round(averageObjectives),
      percentObjectives: Math.round(percentObjectives)
    });
  }, [startDate, endDate]); // Dépendances de l'effet pour le recalcul lors du changement des dates
  const handleApplyDates = () => {
    // Fonction pour mettre à jour startDate et endDate avec les valeurs temporaires
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setIsStartDateManuallySet(true); // Assurez-vous que cette logique est toujours d'actualité
    }
  };

  return <>
  <div className="economies">
    <BreadCrumb text="Eco_title" />
    <h1 className="h1-page">
      <Translation tag={"Eco_title"} />
    </h1>
     {/* Ajouter les sélecteurs de dates */}
     <div className="date-picker">
      <span><Translation tag={"Period"} /> </span>
      <input
        type="date"
        className="date_style"
        value={tempStartDate}
        onChange={(e) => { setTempStartDate(e.target.value); setIsStartDateManuallySet(true); }}
      />
      <span><Translation tag={"To"}/></span>
      <input
        type="date"
        className="date_style"
        value={tempEndDate}
        onChange={(e) => setTempEndDate(e.target.value)}
      />
      <button className="button_valid_date" onClick={handleApplyDates}><Translation tag={"validate"}></Translation></button>
    </div>
    
    <div className="Dashboard-part-2-bis">
        <div className="Chart_dashboard">
          <div className="chart-header">
            <h1 className="Title_graph"><Translation tag={"Savings_period"}/></h1>
          </div>
          <LineChartComponent data={chartData} Temp="Energie" Eau="Eau" TempName="energySaved" EauName="waterSaved" />
        </div>
        <div className="stat_simple">
          <StatCard_simple number={stats.totalWaterSaved.toLocaleString('fr-FR')} text1="L"  text1Color="#FFFFFF"  > </StatCard_simple>
          <StatCard_simple number={Math.round(stats.totalEnergySaved)} text1="kWh"  text1Color="#FFFFFF" > </StatCard_simple>
          <StatCard_simple number={Math.round(stats.totalEnergySaved*0.24)} text1="€"  text1Color="#FFFFFF"  > </StatCard_simple>
        </div>
      </div>
      </div>
      <h1 className="h1-page">
        <Translation tag={"Statistique"}/>
      </h1>
      <div>
      <div className="col_dashboard_number">
        <SmallCard title="Percent_obj"  value={stats.percentObjectives.toLocaleString('fr-FR')} unit="%" />
        <SmallCard title="Number_Shower"  value={stats.numberOfShowers.toLocaleString('fr-FR')} unit="" />
        <SmallCard title="Average_obj"  value={stats.averageObjectives.toLocaleString('fr-FR')} unit="L" />
        </div>
    </div>
  </>;
};

export default Economies ;
