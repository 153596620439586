import { useParams,Link } from "react-router-dom";
import Slidebar from "../component/Slidebar";
import React, { useState, useEffect } from 'react';
import Translation from "utils/Translation";
import LineChartComponent from "component/Linecomponent";
import StatCard_simple from "component/Stat_card_simple";
import bdd_room from "BDD/bdd_room.json";
import bdd_prod from "BDD/bdd_prod.json";
import bdd from "BDD/bdd.json";
import { Col, Row } from "react-bootstrap";
import SmallCard from "component/small_card"

function Room_overview(props) {
  const [tempStartDate, setTempStartDate] = useState('');
  const [tempEndDate, setTempEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [isStartDateManuallySet, setIsStartDateManuallySet] = useState(false);
  const [chartData_consumption, setChartData_consumption] = useState([]);
  const [chartData_economy, setChartData_economy] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [stats, setStats] = useState({
    numberOfShowers: 0,
      totalWaterSaved: 0,
      totalEnergySaved: 0,
      averageObjectives: 0,
      percentObjectives:0,
      totalLitres :0,
      averageTemperature:0,
      averageConsumption:0,
  });
  const standardShowerLitres = 120;
  const standardShowerTemp = 40;
  let waterSavings = 0;
  let energySavings = 0;
  let objectivesCount = 0;
  let objectivesTotal = 0;
  let waterCons=0;
  let total_temp = 0;


  const { roomId } = useParams();
  const findProductNumber = (lRId, bddProd) => {
    const product = bddProd.find(product => product.lRId === lRId);
    return product ? product.lNumProduct : null;
  };
  const douchesConcernees = bdd.filter(douche => douche.shNUM_PRODUIT === findProductNumber(Number(roomId), bdd_prod));

  useEffect(() => {
    if (!isStartDateManuallySet) {
      const initialStartDate = bdd.reduce((minDate, shower) => {
        const showerDate = shower.shTimeCreate.split(' ')[0];
        return minDate < showerDate ? minDate : showerDate;
      }, bdd[0]?.shTimeCreate.split(' ')[0] || new Date().toISOString().split('T')[0]);
  
      setTempStartDate(initialStartDate);
    }
    const filteredData = douchesConcernees.filter((shower) => {
      const showerDate = new Date(shower.shTimeCreate.split(' ')[0]);
      return (!startDate || showerDate >= new Date(startDate)) &&
             (!endDate || showerDate <= new Date(endDate));
    });
    const dailySums_economy = {};
    const dailySums_consumption = {};
    filteredData.forEach(shower => {
      const showerDate = shower.shTimeCreate.split(' ')[0];
      
      // Vérifie si l'entrée pour ce jour existe déjà dans dailySums
      if (!dailySums_economy[showerDate]) {
        dailySums_economy[showerDate] = {
          waterSaved: 0,
          energySaved: 0,
        };
        dailySums_consumption[showerDate] = {
          avgWater: 0,
          avgTemp: 0,
          count :0,
        };
      }
      const showerWaterSaving = standardShowerLitres - shower.shCONSO_DOUCHE;
      const showerEnergySaving = (standardShowerLitres * (standardShowerTemp - 15) * 1.161 / 1000) -
                                  (shower.shCONSO_DOUCHE * (shower.shTEMP_DOUCHE - 15) * 1.161 / 1000);
      waterSavings += showerWaterSaving;
      waterCons += shower.shCONSO_DOUCHE;
      total_temp += shower.shTEMP_DOUCHE;

      energySavings += showerEnergySaving;
      dailySums_economy[showerDate].waterSaved += showerWaterSaving;
      dailySums_economy[showerDate].energySaved += showerEnergySaving;
      dailySums_consumption[showerDate].avgWater += shower.shCONSO_DOUCHE;
      dailySums_consumption[showerDate].avgTemp += shower.shTEMP_DOUCHE;
      dailySums_consumption[showerDate].count++;
      console.log('Temp' + dailySums_consumption[showerDate].avgTemp)
      console.log('Water' +dailySums_consumption[showerDate].avgWater)
      console.log('Count' +dailySums_consumption[showerDate].count)


      if (shower.shOBJ_DOUCHE > 0) {
        objectivesCount++;
        objectivesTotal += shower.shOBJ_DOUCHE;
      }
    });
    const averageObjectives = objectivesCount > 0 ? objectivesTotal / objectivesCount : 0;
    // Calcule le pourcentage des objectifs atteints
    const percentObjectives = objectivesCount > 0 ? (objectivesCount / filteredData.length) * 100 : 0;

    const chartDataWithSums_economy = Object.entries(dailySums_economy).map(([date, sums]) => ({
      date,
      waterSaved: sums.waterSaved,
      energySaved: Math.round(sums.energySaved),
    }));

    const chartDataWithSums_consumption = Object.entries(dailySums_consumption).map(([date, sums]) => ({
      date,
      Conso: sums.avgWater,
      Temp: Math.round(sums.avgTemp/sums.count),
    }));

    console.log(chartData_consumption)
    chartDataWithSums_economy.sort((a, b) => new Date(a.date) - new Date(b.date));
    chartDataWithSums_consumption.sort((a, b) => new Date(a.date) - new Date(b.date));
    setChartData_consumption(chartDataWithSums_consumption);
    setChartData_economy(chartDataWithSums_economy);

    setStats({
      numberOfShowers: filteredData.length,
      totalWaterSaved: Math.round(waterSavings),
      totalEnergySaved: Math.round(energySavings),
      averageObjectives: Math.round(averageObjectives),
      percentObjectives: Math.round(percentObjectives),
      totalLitres : waterCons,
      averageTemperature : Math.round(total_temp/filteredData.length),
      averageConsumption:Math.round(waterCons/filteredData.length),
    });

  }, [startDate, endDate]);
  const handleApplyDates = () => {
    // Fonction pour mettre à jour startDate et endDate avec les valeurs temporaires
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setIsStartDateManuallySet(true); // Assurez-vous que cette logique est toujours d'actualité
    }
  };


  return <>
  <div className="room_overview">
    <div className="breadcrumb">
            <Link to="/hotel"><Translation tag={"Your_hotel"} /></Link> /
            <Link to="/hotel/Lunishower">LuniShower</Link> / 
            <Link to="/hotel/Lunishower/rooms"><Translation tag={"Rooms_title"} /></Link> / 
            <div className="last_elem"> <Translation tag={"Room_nb"}/> {roomId}</div>
        </div>
    <div className="Bouton_titre">
      <Link to="/hotel/lunishower/rooms">
        <button className="more-button-3" >
          <span className="more-arrow-2">←</span>
        </button>
      </Link>
      <h1 className="h1-page-bis">
        <Translation tag={"Room_nb"}/> {roomId}
      </h1>
    </div>
    <div className="date-picker">
      <span>Sur la période du </span>
      <input
        type="date"
        className="date_style"
        value={tempStartDate}
        onChange={(e) => { setTempStartDate(e.target.value); setIsStartDateManuallySet(true); }}
      />
      <span> au </span>
      <input
        type="date"
        className="date_style"
        value={tempEndDate}
        onChange={(e) => setTempEndDate(e.target.value)}
      />
      <button className="button_valid_date" onClick={handleApplyDates}><Translation tag={"validate"}></Translation></button>
    </div>  
    <h1 className="h1-page">
          <Translation tag={"Eco_title"}/>
        </h1>
    <div className="Dashboard-part-2-bis">
      <div className="Chart_dashboard">
        <div className="chart-header">
          <h1 className="Title_graph">Economies sur la période</h1>
        </div>
        <LineChartComponent data={chartData_economy} Temp="Energie (Wh)" Eau="Eau (L)" TempName="energySaved" EauName="waterSaved" />
      </div>
      <div className="stat_simple">
        <StatCard_simple number={stats.totalWaterSaved.toLocaleString('fr-FR')} text1="L"  text1Color="#FFFFFF"  > </StatCard_simple>
        <StatCard_simple number={Math.round(stats.totalEnergySaved)} text1="kWh"  text1Color="#FFFFFF" > </StatCard_simple>
        <StatCard_simple number={Math.round(stats.totalEnergySaved*0.24)} text1="€"  text1Color="#FFFFFF"  > </StatCard_simple>
      </div>
    </div>
    <div>
      <h1 className="h1-page">
        <Translation tag={"Statistique"} />
      </h1>
      <div>
      <div className="col_dashboard_number">
        <SmallCard title="Nombre de douches prises"  value={stats.numberOfShowers.toLocaleString('fr-FR')} unit="" />
        <SmallCard title="Objectif moyen"  value={stats.averageObjectives.toLocaleString('fr-FR')} unit="L" />
        <SmallCard title="Température moyenne"  value={stats.averageTemperature.toLocaleString('fr-FR')} unit="°C" />
        <SmallCard title="Consommations moyenne / douche"  value={stats.averageConsumption.toLocaleString('fr-FR')} unit="L" />

      </div>
        <h1 className="h1-page">
          <Translation tag={"Conso_title"}/>
        </h1>
      <div className="Dashboard-part-2-bis" style={{paddingBottom: "4em"}}>
        <div className="Chart_dashboard">
          <div className="chart-header">
            <h1 className="Title_graph">Consommations sur la période</h1>
          </div>
          <LineChartComponent data={chartData_consumption} Temp="Température (°C)" Eau="Eau(L)" TempName="Temp" EauName="Conso" />
        </div>
        <div className="stat_simple">
          <StatCard_simple number={stats.totalLitres.toLocaleString('fr-FR')} text1="L"  text1Color="#FFFFFF"  > </StatCard_simple>
          <StatCard_simple number={Math.round(stats.totalLitres*(stats.averageTemperature - 15)*1.161/1000)} text1="kWh"  text1Color="#FFFFFF" > </StatCard_simple>
          <StatCard_simple number={Math.round(stats.totalLitres*(stats.averageTemperature - 15)*1.161/10000)} text1="Kg eCO2"  text1Color="#FFFFFF"  > </StatCard_simple>
        </div>
      </div>
    </div>
  </div>
</div></>;
};

export default Room_overview;
