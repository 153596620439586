import Slidebar from "./Slidebar";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Translation from "utils/Translation";
import droplet from "../img/droplet.svg"
import React from "react";
import { Button } from "bootstrap";

const Button_icon = ({ imageSrc, onButtonClick }) => {
    return (
      <button onClick={onButtonClick} className="button-icon">
        <img className="button-icon-image" src={imageSrc} alt="Button" />
      </button>
    );
  };
  



export default Button_icon;
