import React, { useState } from "react";
import HotelApercu from "./HotelApercu";
import { Button } from "react-bootstrap";
import GroupInfos from "views/GroupInfos";

const Groups = (props) => {
    return (
        <>
            {props.group.gName}
            {props.group.gDescription}
            <Button onClick={() => props.setGroupEditable(props.group.gId)}>
                {" "}
                Modifier le groupe{" "}
            </Button>
            {props.group.hCount == 0 ? (
                <div className="cntContener">Ce groupe n'a pas d'hôtels </div>
            ) : (
                props.group.hotels.map((hotel) => (
                    <HotelApercu
                        key={"hotel" + hotel.hId}
                        data={hotel}
                        type={"aperçu"}
                    />
                ))
            )}
        </>
    );
};

export default Groups;
