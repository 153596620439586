import { useKeycloak } from "@react-keycloak/web";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

const ConfirmationDeleteGroup = (props) => {
    const { keycloak, initialized } = useKeycloak();

    const [reponse, setReponse] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const deleteGroup = () => {
        setLoading(true);

        const link = "/api/groups/" + props.groupEditable;

        fetch(link, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + keycloak.token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((res) => {
                console.log(res);
                setHasError(false);
                setLoading(false);
                setReponse(res);
                setIsDeleted(true);
            })
            .catch((err) => {
                setHasError(true);
                setLoading(false);
            });
    };
    return isLoading ? (
        <div>Enregistrement en cours ...</div>
    ) : hasError ? (
        <div>
            Il y a eut une erreur lors de la suppression de votre group
            <Button onClick={() => props.setWantDelete(false)}>Retour</Button>
        </div>
    ) : isDeleted ? (
        <>
            <div>Votre compte a été supprimé</div>
            <Button onClick={() => props.setGroupEditable(null)}>
                {" "}
                Retour{" "}
            </Button>
        </>
    ) : (
        <div>
            Êtes-vous sûr de vouloir supprimer ce group?{" "}
            <Button onClick={() => deleteGroup()}>OUI</Button>{" "}
            <Button onClick={() => props.setWantDelete(false)}>NON</Button>
        </div>
    );
};

export default ConfirmationDeleteGroup;
