// StatCard.js
import React from 'react';
import Translation from 'utils/Translation';

function StatCard(props) {
  return (
    <div className="stat-card" style={{ color: props.text1Color }}>
        {/* <div className="logo-image-2">
        {props.icon && <img src={props.icon} alt="Icon" className="Logo-svg-2" />}
        </div> */}
      <div style={{ color: props.text1Color }}>
        <span className="stat-number">{props.number}</span> <span className="stat-text1"><Translation tag={props.text1}/></span>   
      </div>
      <div className="stat-text2"><Translation tag={props.text2}/></div>
      
    </div>
  );
}

export default StatCard;
