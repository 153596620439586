import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

InfoForm.propTypes = {
    status: PropTypes.oneOf(["OK", "NOK"]),
};

function InfoForm(props) {
    let msg, style, numerror;

    // console.log('props')
    // console.log(props)

    if (props.reponse.message) {
        numerror = props.reponse.message;
    } else {
        numerror = "unknown";
    }

    if (props.status == "OK") {
        msg = "Les modifications ont bien été enregistrées";
        style = "sucess";
    } else {
        msg =
            "Un problème est survenu lors de l'enregistrement, essayez de nouveau. Erreur : " +
            numerror;

        style = "error";
    }

    return <div className={classnames("statusForm", style)}>{msg}</div>;
}

export default InfoForm;
